import TopMenu from "../../components/TopMenu";
import { useState } from "react";

import "./styles.css";
import PageTitle from "../../components/PageTitle";
import BottomMenu from "../../components/BottomMenu";
import { RemoveAccount } from "../../utils/API/auth";
import { useNotifications } from "../../utils/NotificationContext";
import CustomLink from "../../utils/customLink";
import useCustomNavigate from "../../utils/CustomNavigate";

const AccountDeletePage = () => {
  const navigator = useCustomNavigate();

  const [buttonLoading, setButtonLoading] = useState(false);
  const { addNotification } = useNotifications();

  const handleDeleteAccount = async () => {
    setButtonLoading(true);
    try {
      let removeResult = await RemoveAccount();

      if (removeResult !== 0) {
        setButtonLoading(false);
        addNotification(removeResult.message);
        return;
      }

      addNotification("Your account has been deleted...");
      navigator("/");
      return;
    } catch (err) {
      setButtonLoading(false);
      addNotification("An error has occured while deleting the account");
    }
  };

  return (
    <>
      <TopMenu backLink="account" showMenu={false} />
      <PageTitle
        dimension="small-middle"
        subtitle="Your progress data will be lost."
      >
        Are you sure you want to delete
        <br />
        your Biotweak account?
      </PageTitle>

      <div className="absolute delete-account-options-wrapper">
        <CustomLink to="/home">I want to keep my account</CustomLink>
        <p onClick={handleDeleteAccount} className="delete">
          {buttonLoading
            ? "Deleting your account..."
            : "Permanently delete account"}
        </p>
      </div>

      <BottomMenu except={["challenge"]} />
    </>
  );
};

export default AccountDeletePage;
