import { useLocation } from "react-router-dom";
import PageTitle, { LoadingTitle } from "../../components/PageTitle";
import { useEffect, useMemo, useState } from "react";
import CustomButton from "../../components/Buttons";
import FormInputText from "../../components/FormInputText";

import "./styles.css";
import AppLogoBottom from "../../components/AppLogoBottom";
import TopMenu from "../../components/TopMenu";
import { capitalizeFirstLetter } from "../../utils/str";
import { setNotification } from "../../utils/Notifications";
import { useNotifications } from "../../utils/NotificationContext";
import useCustomNavigate from "../../utils/CustomNavigate";

const RegisterLocationPage = () => {
  const navigator = useCustomNavigate();
  const location = useLocation();

  const [newUserData, setNewUserData] = useState(null);
  const { addNotification } = useNotifications();

  useEffect(() => {
    if (!location || !location.state || !location.state.userData) {
      setNotification("Error loading page...");
      navigator("/");
      return;
    }

    setNewUserData({
      name: location.state.userData.name,
      surname: location.state.userData.surname,
      email: location.state.userData.email,
      location: "",
      accepts_tc: location.state.userData.accepts_tc,
    });
  }, []);

  const handleSendLocation = () => {
    addNotification("Welcome! Check your e-mail to register!");
    navigator("/hello/verify", {
      state: {
        userData: newUserData,
        email: location.state.email,
        type: "register",
      },
    });
  };

  const [showLocationSelector, setShowLocationSelector] = useState(false);

  const locationJSX = useMemo(() => {
    if (
      !location ||
      !location.state ||
      !location.state.officeLocations ||
      !newUserData
    ) {
      return <></>;
    }

    return location.state.officeLocations.map((loc) => (
      <li
        onClick={() =>
          setNewUserData({
            ...newUserData,
            location: loc.id,
            locationName: loc.name,
          })
        }
        key={loc.id}
      >
        <span
          className={`radio-wrapper ${
            newUserData.location === loc.id ? "checked" : ""
          }`}
        >
          <span className="radio">
            <span className="interior"></span>
          </span>
        </span>
        {loc.name}
      </li>
    ));
  }, [location, newUserData]);

  if (!newUserData) {
    return <LoadingTitle />;
  }

  return (
    <>
      <PageTitle dimension="small">Where is your office?</PageTitle>

      <form className="absolute account-register-form">
        <FormInputText
          name="location"
          id="location"
          onFocus={() => setShowLocationSelector(true)}
          value={
            newUserData.locationName
              ? capitalizeFirstLetter(newUserData.locationName)
              : ""
          }
          autocomplete="off"
          readOnly="readonly"
        >
          Office Location
        </FormInputText>

        <CustomButton
          variant="primary"
          className="button-save-location"
          disabled={!newUserData || !newUserData.location}
          onClick={handleSendLocation}
        >
          Save
        </CustomButton>
      </form>

      {showLocationSelector && (
        <>
          <div className="absolute z-top-1 location-selector-wrapper">
            <TopMenu
              backLink="back"
              showMenu={false}
              overloadBack={() => setShowLocationSelector(false)}
              className="z-top-2"
            />
            <PageTitle dimension="small">Where are you located?</PageTitle>

            <div className="absolute location-selector">
              <ul>{locationJSX}</ul>
            </div>

            <CustomButton
              variant="primary"
              className="absolute  button-save-location"
              onClick={() => setShowLocationSelector(false)}
              disabled={newUserData.location === ""}
            >
              Save
            </CustomButton>
          </div>
        </>
      )}

      <AppLogoBottom />
    </>
  );
};

export default RegisterLocationPage;
