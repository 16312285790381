import { useLocation } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { useEffect, useRef } from "react";

import AppLogoBottom from "../../components/AppLogoBottom";
import { handleRequestRegister } from "../../utils/API/auth";
import { setNotification } from "../../utils/Notifications";
import { useNotifications } from "../../utils/NotificationContext";
import useCustomNavigate from "../../utils/CustomNavigate";

const RegisterSendToken = () => {
  const hasPosted = useRef(false);

  const location = useLocation();
  const { addNotification } = useNotifications();

  const navigator = useCustomNavigate();

  useEffect(() => {
    if (
      !location ||
      !location.state ||
      !location.state.token ||
      !location.state.userData ||
      !location.state.email
    ) {
      addNotification("An error has occured...");
      navigator("/hello");
    }

    (async () => {
      if (hasPosted.current) return;
      hasPosted.current = true;
      try {
        let registerResponse = await handleRequestRegister(
          location.state.token,
          location.state.email,
          location.state.userData
        );

        if (registerResponse !== 0) {
          setNotification(registerResponse.message);
          navigator("/hello/verify", {
            state: {
              type: "register",
              email: location.state.email,
              userData: location.state.userData,
            },
          });
          return;
        }

        setNotification("Register successful. Welcome!");
        navigator("/home");
        return;
      } catch (err) {
        setNotification("We have encountered an error. Please try again.");
        navigator("/hello/verify", {
          state: {
            type: "register",
            email: location.state.email,
            userData: location.state.userData,
          },
        });
      }
    })();
  }, [location]);

  return (
    <>
      <PageTitle dimension="big-middle" subtitle="Hang on...">
        Registering you
      </PageTitle>

      <AppLogoBottom />
    </>
  );
};

export default RegisterSendToken;
