import CustomButton from "../../components/Buttons";
import PageTitle from "../../components/PageTitle";
import TopMenu from "../../components/TopMenu";

import "./styles.css";
import AppLogoBottom from "../../components/AppLogoBottom";
import useCustomNavigate from "../../utils/CustomNavigate";
const PostFeedbackPage = () => {
  const navigator = useCustomNavigate();
  return (
    <>
      <TopMenu backLink="feedback" showMenu={false} />
      <PageTitle dimension="small-middle">
        Thank you for your feedback!
      </PageTitle>

      <CustomButton
        variant="primary"
        className="absolute post-feedback-back-button"
        onClick={() => navigator("/home")}
      >
        Back to app{" "}
      </CustomButton>

      <AppLogoBottom />
    </>
  );
};

export default PostFeedbackPage;
