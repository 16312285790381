import React, { createContext, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

export const NotificationContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();

  useEffect(() => {
    let storedNotifications = localStorage.getItem("BTK-notifications");

    if (storedNotifications) {
      setNotifications(JSON.parse(storedNotifications));
    } else setNotifications([]);
  }, [location]);

  useEffect(() => {
    localStorage.setItem("BTK-notifications", JSON.stringify(notifications));
  }, [notifications]);

  const addNotification = (message, timeStart = 10) => {
    setNotifications([
      ...notifications,
      { text: message, id: Date.now(), timeStart: timeStart },
    ]);
  };

  const removeNotification = (id) => {
    let newNotifications = [...notifications];
    newNotifications = newNotifications.filter((notif) => notif.id !== id);
    setNotifications(newNotifications);
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
