import "./styles.css";
const ChallengeCompletedRating = ({ feedback, onFeedbackClicked }) => {
  const rating_options = [1, 2, 3, 4].map((num) => {
    return (
      <div key={num} onClick={() => onFeedbackClicked(num)}>
        <img
          className={`${feedback === num ? "active" : ""}`}
          src={`/assets/images/rating/rating${num}.svg`}
          alt={`Rating value ${num}/4`}
        />
      </div>
    );
  });
  return (
    <div className="absolute challenge-completed-rating-wrapper">
      <p>
        How do you feel after <br />
        today's challenge?
      </p>

      <div className="picker">{rating_options}</div>
    </div>
  );
};

export default ChallengeCompletedRating;
