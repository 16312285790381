import { useEffect, useRef, useState } from "react";
import CustomButton from "../../components/Buttons";
import FormInputText from "../../components/FormInputText";
import PageTitle, { LoadingTitle } from "../../components/PageTitle";

import "./styles.css";
import { handleRequestCode } from "../../utils/API/auth";
import { useLocation } from "react-router-dom";
import { useNotifications } from "../../utils/NotificationContext";
import CustomLink from "../../utils/customLink";
import useCustomNavigate from "../../utils/CustomNavigate";

const AuthenticateVerifyPage = () => {
  const location = useLocation();
  const [authCode, setCode] = useState("");

  const [secondsUntilRetry, setSecondsUntilRetry] = useState(10);
  const [retryDisappearing, setRetryDisappearing] = useState(false);

  const navigator = useCustomNavigate();
  const intervalRef = useRef(null);

  const { addNotification } = useNotifications();

  useEffect(() => {
    if (
      !location ||
      !location.state ||
      !location.state.email ||
      !location.state.type
    ) {
      addNotification("An error has occured...");
      navigator("/");
      return;
    }
  }, [location]);

  const type = location.state.type;

  const handleRetry = async () => {
    handleRequestCode(location.state.email)
      .then((result) => {
        addNotification("E-mail sent!");
      })
      .catch((err) => {
        addNotification("An error occured... Please try again");
      });

    setRetryDisappearing(true);
    setTimeout(() => {
      setSecondsUntilRetry(10);
      setRetryDisappearing(false);
    }, 1000);
  };

  const handleSendCode = () => {
    if (type === "register") {
      navigator(`/register/send`, {
        state: {
          token: authCode,
          userData: location.state.userData,
          email: location.state.email,
        },
      });
      return;
    }
    if (type === "login") {
      navigator(`/login/send`, {
        state: { token: authCode, email: location.state.email },
      });
      return;
    }
  };

  useEffect(() => {
    const id = setInterval(() => {
      setSecondsUntilRetry((s) => s - 1);
    }, 1000);

    intervalRef.current = id;

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  if (
    !location ||
    !location.state ||
    !location.state.email ||
    !location.state.type
  ) {
    return <LoadingTitle />;
  }

  return (
    <>
      <PageTitle
        dimension="medium"
        title_style={{ lineHeight: "35px" }}
        subtitle={location.state.email}
        subtitle_class="email"
        tiny_title="Find your one-time password in your inbox!"
      >
        Verify E-mail
      </PageTitle>

      <div className="absolute authenticate-input">
        <FormInputText
          className="auth_code"
          style={{ width: "70%" }}
          value={authCode}
          onInput={(e) => setCode(e.target.value.toUpperCase().slice(0, 6))}
          autocomplete="off"
        >
          Type code here
        </FormInputText>

        <CustomButton
          variant="primary"
          className="w-100"
          onClick={handleSendCode}
          disabled={authCode.length < 6}
        >
          Continue to BioTweak
        </CustomButton>
      </div>

      {secondsUntilRetry <= 0 && (
        <div
          className={`absolute retry-wrapper no-bg ${
            retryDisappearing ? "disappearing" : ""
          }`}
        >
          <p>
            Did not get the e-mail? <br />
            Check your Spam folder or....
          </p>
          <p onClick={handleRetry}>RE-SEND MAIL</p>
        </div>
      )}

      <div className="absolute different-account no-bg">
        <CustomLink to="/hello">Use a different account</CustomLink>
      </div>

      <div className="absolute verify-mascot no-bg">
        <img
          src="/assets/images/mascot-id.png"
          alt="The BioTweak Mascot holding an identification document."
        />
      </div>
    </>
  );
};

export default AuthenticateVerifyPage;
