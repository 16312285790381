import { useLocation } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import AppLogoBottom from "../../components/AppLogoBottom";
import { useEffect } from "react";
import { handleRequestLogin } from "../../utils/API/auth";
import { setNotification } from "../../utils/Notifications";
import useCustomNavigate from "../../utils/CustomNavigate";

const LoginSendToken = () => {
  const location = useLocation();

  let login_token = null;
  let login_email = null;

  const navigator = useCustomNavigate();
  if (location.state && location.state.token) {
    login_token = location.state.token;
  }

  if (location.state && location.state.email) {
    login_email = location.state.email;
  }

  if (!login_token || !login_email) {
    setNotification("Could not authenticate you...");
    navigator(`/login`);
  }

  useEffect(() => {
    (async () => {
      try {
        let loginResponse = await handleRequestLogin(login_token, login_email);

        if (loginResponse !== 0) {
          setNotification(loginResponse.message);
          navigator(`/hello/verify`, {
            state: {
              type: "login",
              email: location.state.email,
            },
          });
          return;
        }

        setNotification("Login successful. Welcome back!");
        navigator("/home");
        return;
      } catch (err) {
        setNotification("We have encountered an error. Please try again.");
        navigator("/");
      }
    })();
  }, [login_token, login_email, location]);

  return (
    <>
      <PageTitle dimension="big-middle" subtitle="Hang on...">
        Logging you in
      </PageTitle>

      <AppLogoBottom />
    </>
  );
};

export default LoginSendToken;
