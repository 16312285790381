import "./styles.css";

const AppLogoBottom = () => {
  return (
    <div className="absolute no-bg logo-bottom-wrapper">
      <img src="/assets/images/logo.svg" alt="logo" />
      <span>
        <span>Bio</span>
        <span>Tweak</span>
      </span>
    </div>
  );
};

export default AppLogoBottom;
