import { useNotifications } from "../../utils/NotificationContext";
import ScrollContent from "../../components/ScrollContent";

import "./styles.css";
import TopMenu from "../../components/TopMenu";
import { getLegalInformation } from "../../utils/API/interaction";
import { useEffect, useState } from "react";
import { LoadingTitle } from "../../components/PageTitle";
import useCustomNavigate from "../../utils/CustomNavigate";
const AboutPage = () => {
  const { addNotification } = useNotifications();
  const navigator = useCustomNavigate();
  const [aboutInformation, setAboutInformation] = useState(null);

  useEffect(() => {
    (async () => {
      let newAboutInformation = await getLegalInformation(["ABOUT"]);

      if (newAboutInformation.code === 200) {
        setAboutInformation(newAboutInformation.data);
        return;
      } else {
        addNotification(newAboutInformation.message);
        navigator("/home");
      }
    })();
  }, []);

  if (!aboutInformation)
    return (
      <>
        <TopMenu backLink="settings" showMenu={false} /> <LoadingTitle />
      </>
    );
  return (
    <>
      <TopMenu backLink="settings" showMenu={false} />
      <ScrollContent className="absolute about-wrapper">
        {aboutInformation.map((about, index) => (
          <div key={about.key}>
            <h2>{about.title}</h2>
            {about.text.map((p, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: p }}></p>
            ))}
          </div>
        ))}
      </ScrollContent>
    </>
  );
};

export default AboutPage;
