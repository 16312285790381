// include styles.css
import "./styles.css";

import BottomMenu from "../../components/BottomMenu";
import TopMenu from "../../components/TopMenu";
import PageTitle from "../../components/PageTitle";
import LearnContent from "../../components/LearnContent";
import ScrollContent from "../../components/ScrollContent";

const LearningPage = () => {
  return (
    <>
      <TopMenu />
      <PageTitle dimension="big-top">Learn</PageTitle>
      <ScrollContent className="absolute learn-scroll-content">
        <LearnContent />
      </ScrollContent>
      <BottomMenu active="learning"></BottomMenu>
    </>
  );
};

export default LearningPage;
