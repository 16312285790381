import React from "react";
import { useLocation, Navigate } from "react-router-dom";

const CustomNavigate = ({ to, ...props }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  let navigateTo = to;
  if (source) {
    // Check if 'to' is an object or a string and modify accordingly
    const isObj = typeof to === "object";
    const search = isObj ? to.search : "";
    navigateTo = isObj
      ? { ...to, search: `${search ? search + "&" : "?"}source=${source}` }
      : `${to}?source=${source}`;
  }

  return <Navigate to={navigateTo} {...props} />;
};

export default CustomNavigate;
