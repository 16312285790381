import { getDateList, getDateListFull } from "../time";
import { getCookie } from "../cookie";
import axios from "axios";

function updateUserLocalStorage(data) {
  const content = {
    name: data.name,
    surname: data.surname,
    email: data.email,
    location: data.officeLocation._id,
    locationName: data.officeLocation.name,
    allowNotifications: data.allowNotifications,
    timeOffset: data.timeOffset,
  };

  localStorage.setItem("BTK-user", JSON.stringify(content));

  // clear the privacy settigns for single events

  Object.keys(localStorage)
    .filter((x) => x.endsWith("-allow-notifications") && x.startsWith("BTK-"))
    .forEach((x) => localStorage.removeItem(x));
}

const updateUserInformation = async (data) => {
  let response = 0;

  const body = {
    name: data.name,
    surname: data.surname,
    officeLocation: data.location,
    allowNotifications: data.allowNotifications,
    timeOffset: new Date().getTimezoneOffset(),
  };
  await axios
    .put(process.env.REACT_APP_API_URL + "/user/update", body, {
      headers: {
        token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
      },
    })
    .then((res) => {
      let newUserDetails = res.data;

      updateUserLocalStorage(newUserDetails);
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
};

function getUser() {
  if (localStorage.getItem("BTK-user"))
    return JSON.parse(localStorage.getItem("BTK-user"));
  return null;
}

const getProgressCalendar = async () => {
  let response = 0;
  await axios
    .get(
      process.env.REACT_APP_API_URL + "/user/get-calendar",

      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      let completedChallenges = res.data.events;

      let calendar;
      let currentStreak;
      let noChallengesCompleted;

      if (res.data.format === 5)
        [calendar, currentStreak, noChallengesCompleted] =
          getDateList(completedChallenges);
      else
        [calendar, currentStreak, noChallengesCompleted] =
          getDateListFull(completedChallenges);

      response = {
        code: 200,
        calendar: calendar,
        currentStreak: currentStreak,
        noChallengesCompleted: noChallengesCompleted,
        type: res.data.format,
      };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
};

async function getCompletedEvents() {
  let response = 0;
  await axios
    .get(
      process.env.REACT_APP_API_URL + "/user/count-completed-events",

      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      response = {
        code: 200,
        challenges_completed: res.data.numberOfCompletedEvents,
      };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

async function getOfficeLocations() {
  let response = 0;
  await axios
    .get(
      process.env.REACT_APP_API_URL + "/user/office-locations",

      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      const office_locations = res.data.map((loc) => {
        return { id: loc._id, name: loc.name };
      });
      response = {
        code: 200,
        officeLocations: office_locations,
      };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

export {
  getUser,
  getProgressCalendar,
  getCompletedEvents,
  getOfficeLocations,
  updateUserInformation,
  updateUserLocalStorage,
};
