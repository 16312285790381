import useCustomNavigate from "../../utils/CustomNavigate";
import "./styles.css";

const TopMenuItem = ({
  imageName = "",
  link = "",
  linkState = {},
  overloadBack = null,
}) => {
  const navigator = useCustomNavigate();
  const handleClick = () => {
    if (overloadBack !== null) {
      overloadBack();
      return;
    }
    navigator("/" + link, { state: linkState });
  };
  return (
    <div
      className="top-menu-item no-bg d-flex align-items-center justify-content-center"
      onClick={handleClick}
    >
      <img src={`/assets/images/${imageName}.svg`} alt={`${link} menu icon`} />
    </div>
  );
};

export default TopMenuItem;
