import "./styles.css";

const CustomButton = ({
  variant = "primary",
  children,
  disabled = false,
  className = "",
  onClick = null,
  style = {},
}) => {
  return (
    <button
      disabled={disabled}
      className={`button-variant button-variant-${variant} ${className} ${
        disabled ? "disabled" : ""
      }`}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default CustomButton;
