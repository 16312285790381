import "./styles.css";

const FormInputTextArea = ({
  children,
  name = "",
  id = "",
  className = "",
  onChange = () => {},
  onInput = () => {},
  value = "",
}) => {
  return (
    <textarea
      name={name ? name : children}
      id={id ? id : children}
      className={`form-input-textarea ${className}`}
      placeholder={children}
      onChange={onChange}
      onInput={onInput}
      value={value}
    ></textarea>
  );
};

export default FormInputTextArea;
