import { useLocation } from "react-router-dom";
import TopMenuItem from "../TopMenuItem";

import "./styles.css";
const TopMenu = ({
  backLink = "",
  backLinkState = {},
  menuLink = "settings",
  showMenu = true,
  overloadBack = null,
  menuImageName = "menu",
}) => {
  const location = useLocation();
  var justifyClass = "justify-content-between";
  if (!backLink) justifyClass = "justify-content-end";
  if (!showMenu) justifyClass = "justify-content-start";

  return (
    <div
      className={`absolute no-bg top-menu d-flex flex-row ${justifyClass} align-items-center`}
    >
      {backLink && (
        <TopMenuItem
          imageName="back"
          link={backLink}
          linkState={backLinkState}
          overloadBack={overloadBack}
        />
      )}

      {showMenu && (
        <TopMenuItem
          imageName={menuImageName}
          link={menuLink}
          linkState={{ back: location.pathname.substring(1) }}
        />
      )}
    </div>
  );
};

export default TopMenu;
