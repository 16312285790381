import axios from "axios";
import { getCookie } from "../cookie";
import {
  getCurrentDayFormat,
  getPrettyDateFormatCalendarNotification,
} from "../time";

async function sendUserFeedback(content, allows_contact) {
  let response = 0;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/user/send-feedback",
      {
        message: content,
        contact: allows_contact,
      },
      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      response = {
        code: 200,
      };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

async function getChallengeNotification() {
  let response = 0;

  if (
    localStorage.getItem("BTK-challenge-notification-" + getCurrentDayFormat())
  ) {
    response = JSON.parse(
      localStorage.getItem(
        "BTK-challenge-notification-" + getCurrentDayFormat()
      )
    );
    return response;
  }

  await axios
    .get(
      process.env.REACT_APP_API_URL + "/user/get-notification",

      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      if (!res.data) {
        response = {
          code: 404,
          message: "No notifications",
        };
      } else {
        response = {
          code: 200,
          notification: {
            message: res.data.message,
            user_id: res.data.user,
            notification_id: res.data._id,
            item_date: getCurrentDayFormat(),
          },
        };
        localStorage.setItem(
          "BTK-challenge-notification-" + getCurrentDayFormat(),
          JSON.stringify(response)
        );
      }
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 404)
        response = {
          code: 404,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

async function shareAchievementInformation(achievement_id) {
  let response = 0;
  await axios
    .post(
      process.env.REACT_APP_API_URL +
        "/user/share-achievement/" +
        achievement_id,
      {},
      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      response = {
        code: 200,
      };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

async function sendNotificationCongrats(notif_id) {
  let response = 0;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/user/send-congrats/" + notif_id,
      {},
      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      response = {
        code: 200,
      };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

async function getCalendarNotification() {
  let response = 0;

  await axios
    .get(
      process.env.REACT_APP_API_URL + "/user/get-recent-congrat",

      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      if (!res.data) {
        response = {
          code: 404,
          message: "No notifications",
        };
      } else {
        response = {
          code: 200,
          notification: {
            message: res.data.message,
            date: getPrettyDateFormatCalendarNotification(
              new Date(res.data.createdAt)
            ),
          },
        };
        localStorage.setItem(
          "BTK-calendar-notification",
          JSON.stringify(response)
        );
      }
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 404)
        response = {
          code: 404,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  if (localStorage.getItem("BTK-calendar-notification")) {
    response = JSON.parse(localStorage.getItem("BTK-calendar-notification"));
    return response;
  }

  return response;
}

function markCalendarNotificationViewed() {
  //localStorage.removeItem("BTK-calendar-notification-" + getCurrentDayFormat());
}

async function getAllCongrats() {
  let response = 0;

  if (localStorage.getItem("BTK-all-congrats-" + getCurrentDayFormat())) {
    response = JSON.parse(
      localStorage.getItem("BTK-all-congrats-" + getCurrentDayFormat())
    );

    return response;
  }

  await axios
    .get(
      process.env.REACT_APP_API_URL + "/user/get-congrats",

      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      if (!res.data) {
        response = {
          code: 404,
          message: "No notifications",
        };
      } else {
        response = {
          code: 200,
          notifications: res.data.map((notification) => {
            return {
              message: notification.message,
              date: getPrettyDateFormatCalendarNotification(
                new Date(notification.createdAt)
              ),
            };
          }),
          item_date: getCurrentDayFormat(),
        };

        localStorage.setItem(
          "BTK-all-congrats-" + getCurrentDayFormat(),
          JSON.stringify(response)
        );
      }
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 404)
        response = {
          code: 404,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

async function getLegalInformation(specific = false) {
  let response = 0;

  let storageText = "BTK-" + (specific ? specific.join("-") + "-" : "tc-");

  if (localStorage.getItem(storageText + getCurrentDayFormat())) {
    response = JSON.parse(
      localStorage.getItem(storageText + getCurrentDayFormat())
    );

    return response;
  }

  await axios
    .get(process.env.REACT_APP_API_URL + "/user/legal", {
      headers: {
        token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
      },
    })
    .then((res) => {
      let response_data = [];
      for (let property in res.data) {
        response_data.push({
          key: property,
          text: res.data[property].text.split("\n"),
          title: res.data[property].title,
        });
      }
      response = {
        code: 200,
        data: response_data,
        item_date: getCurrentDayFormat(),
      };

      if (specific)
        response = {
          ...response,
          data: response.data.filter((text) => {
            return specific.find((e) => e === text.key);
          }),
        };

      if (response.data.length > 0)
        localStorage.setItem(
          storageText + getCurrentDayFormat(),
          JSON.stringify(response)
        );
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      }
      //console.log(err);
      else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 404)
        response = {
          code: 404,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  //console.log(response);
  return response;
}

export {
  sendUserFeedback,
  getChallengeNotification,
  getCalendarNotification,
  sendNotificationCongrats,
  getAllCongrats,
  getLegalInformation,
  shareAchievementInformation,
  markCalendarNotificationViewed,
};
