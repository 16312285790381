import { useEffect, useRef } from "react";
import "./styles.css";

const ScrollContent = ({
  className = "",
  scrollToBottom = false,
  children,
}) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (scrollToBottom) {
      divRef.current.scrollTo({
        top: divRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [children, scrollToBottom]);

  return (
    <div ref={divRef} className={`scroll-content ${className}`}>
      {children}
    </div>
  );
};

export default ScrollContent;
