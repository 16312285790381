import axios from "axios";
import { getCurrentDayFormat } from "../time";
import { getCookie } from "../cookie";

const getLearningArticle = async (id) => {
  let response = 0;

  if (
    localStorage.getItem("BTK-learning-" + id + "-" + getCurrentDayFormat())
  ) {
    response = JSON.parse(
      localStorage.getItem("BTK-learning-" + id + "-" + getCurrentDayFormat())
    );

    return response;
  }

  await axios
    .get(
      process.env.REACT_APP_API_URL + "/user/topics/" + id,

      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      if (!res.data) {
        response = {
          code: 404,
          message: "The article was not found...",
        };
      } else {
        response = {
          code: 200,
          article: {
            id: res.data._id,
            title: res.data.title,
            content: res.data.paragraphs.split("\n"),
            thumbnail: res.data.thumbnail,
          },
          item_date: getCurrentDayFormat(),
        };

        localStorage.setItem(
          "BTK-learning-" + id + "-" + getCurrentDayFormat(),
          JSON.stringify(response)
        );
      }
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 404)
        response = {
          code: 404,
          message: "The learning article could not be found.",
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
};

const getLearningArticles = async (limit = 0) => {
  let response = 0;

  if (localStorage.getItem("BTK-learning-" + getCurrentDayFormat())) {
    response = JSON.parse(
      localStorage.getItem("BTK-learning-" + getCurrentDayFormat())
    );

    if (limit > 0) response.articles = response.articles.slice(0, limit);
    return response;
  }

  await axios
    .get(
      process.env.REACT_APP_API_URL + "/user/topics",

      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      if (!res.data) {
        response = {
          code: 404,
          message: "No learning articles found...",
        };
      } else {
        response = {
          code: 200,
          articles: res.data.map((article) => {
            return {
              id: article._id,
              title: article.title,
              thumbnail: article.thumbnail,
            };
          }),
          item_date: getCurrentDayFormat(),
        };

        localStorage.setItem(
          "BTK-learning-" + getCurrentDayFormat(),
          JSON.stringify(response)
        );
        if (limit > 0) response.articles = response.articles.slice(0, limit);
      }
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 404)
        response = {
          code: 404,
          message: "The learning articles could not be found.",
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
};

export { getLearningArticle, getLearningArticles };
