import "./styles.css";

const ProgressPageInfoBubble = ({ streak }) => {
  return (
    <div className="absolute progress-page-info-bubble-wrapper">
      <div className="bubble">{streak}</div>
      <p>{streak !== 1 ? "Days in a row" : "Day"}</p>
    </div>
  );
};

export default ProgressPageInfoBubble;
