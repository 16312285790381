import { useNavigate, useLocation } from "react-router-dom";

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const customNavigate = (to, options) => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");

    if (source) {
      const isObj = typeof to === "object";
      const search = isObj ? to.search : "";
      to = isObj
        ? { ...to, search: `${search ? search + "&" : "?"}source=${source}` }
        : `${to}?source=${source}`;
    }

    navigate(to, options);
  };

  return customNavigate;
};

export default useCustomNavigate;
