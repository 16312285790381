const useGetMascot = (specificMascot = -1) => {
  let images = [
    "/assets/images/doctor.svg",
    "/assets/images/coach.svg",
    "/assets/images/hippie.svg",
  ];

  let mascot = images[0];

  if (
    specificMascot !== null &&
    specificMascot >= 0 &&
    specificMascot < images.length
  )
    mascot = images[specificMascot];
  else mascot = images[Math.floor(Math.random() * images.length)];

  return mascot;
};

export default useGetMascot;
