import { useLocation } from "react-router-dom";
import PageTitle, { LoadingTitle } from "../../components/PageTitle";
import { setNotification } from "../../utils/Notifications";
import { useEffect, useState } from "react";
import TopMenu from "../../components/TopMenu";
import FormRadio from "../../components/FormRadio";

import "./styles.css";
import { getUser } from "../../utils/API/user";
import useCustomNavigate from "../../utils/CustomNavigate";
const ChallengeSettingsPage = () => {
  const location = useLocation();
  const navigator = useCustomNavigate();
  useEffect(() => {
    if (!location || !location.state || !location.state.challengeID) {
      navigator("/challenge");
      setNotification("Error loading page...");
    }
  }, [location]);

  const [allowShare, setAllowShare] = useState(null);

  useEffect(() => {
    // Update challenge settings
    if (allowShare === null || allowShare === undefined) return;

    localStorage.setItem(
      "BTK-" + location.state.challengeID + "-allow-notifications",
      allowShare.toString()
    );
  }, [allowShare, location]);

  useEffect(() => {
    if (
      localStorage.getItem(
        "BTK-" + location.state.challengeID + "-allow-notifications"
      )
    ) {
      setAllowShare(
        localStorage.getItem(
          "BTK-" + location.state.challengeID + "-allow-notifications"
        ) === "true"
      );
    } else {
      setAllowShare(getUser().allowNotifications);
    }
  }, [location]);

  if (!location || !location.state || !location.state.challengeID) {
    return (
      <>
        <TopMenu backLink="challenge" showMenu={false} />
        <LoadingTitle />
      </>
    );
  }
  return (
    <>
      <TopMenu backLink="challenge" showMenu={false} />
      <PageTitle dimension="small-middle">Privacy Options</PageTitle>

      <div className="absolute privacy-options-wrapper">
        <div className="half option-title">Do this challenge anonymously</div>
        <div className="half option-button">
          <FormRadio
            active={!allowShare}
            onFormChange={() => setAllowShare((a) => !a)}
          />
        </div>
        <div className="full option-description">
          If selected, your colleagues will not see that you completed this
          specific challenge. This will not affect your challenge count or your
          streak.
        </div>
      </div>
    </>
  );
};

export default ChallengeSettingsPage;
