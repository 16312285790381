import BottomMenuItem from "../BottomMenuItem";

import "./styles.css";
const BottomMenu = ({
  active = "",
  except = [],
  include = [],
  challenge_info,
  info_state = {},
}) => {
  return (
    <>
      {include.includes("info") && (
        <div className="absolute no-bg bottom-menu-info d-flex flex-row justify-content-center align-items-center">
          <BottomMenuItem
            imageName="info"
            active={active === "info" ? true : false}
            link="challenge/info"
            linkState={info_state}
            challenge_info={challenge_info}
            extension="svg"
          >
            {" "}
            Info{" "}
          </BottomMenuItem>
        </div>
      )}
      <div className="absolute no-bg bottom-menu d-flex flex-row justify-content-between align-items-center">
        {!except.includes("challenge") && (
          <BottomMenuItem
            imageName="challenge"
            active={active === "challenge" ? true : false}
          >
            {" "}
            Up now{" "}
          </BottomMenuItem>
        )}

        {!except.includes("progress") && (
          <BottomMenuItem
            imageName="progress"
            active={active === "progress" ? true : false}
          >
            {" "}
            My progress{" "}
          </BottomMenuItem>
        )}

        {!except.includes("learning") && (
          <BottomMenuItem
            imageName="learning"
            active={active === "learning" ? true : false}
          >
            {" "}
            Learning{" "}
          </BottomMenuItem>
        )}
      </div>
    </>
  );
};

export default BottomMenu;
