import AppLogoBottom from "../../components/AppLogoBottom";
import PageTitle from "../../components/PageTitle";
import TopMenu from "../../components/TopMenu";
import FormInputTextArea from "../../components/FormInputTextArea";

import { useState } from "react";

import "./styles.css";
import CustomButton from "../../components/Buttons";
import { sendUserFeedback } from "../../utils/API/interaction";
import { setNotification } from "../../utils/Notifications";
import { LogOut } from "../../utils/API/auth";
import useCustomNavigate from "../../utils/CustomNavigate";

const FeedbackPage = () => {
  const [feedbackText, setFeedbackText] = useState("");
  const [userAllowsContact, setUserAllowsContact] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigator = useCustomNavigate();
  const handleSendFeedback = async () => {
    setButtonLoading(true);

    try {
      let insertResult = await sendUserFeedback(
        feedbackText,
        userAllowsContact
      );

      if (insertResult.code === 200) {
        navigator("/feedback/thank-you");
        setButtonLoading(false);
        return;
      } else if (insertResult.code === 400) {
        setNotification(insertResult.message);
        navigator("/learning");
        return;
      } else if (insertResult.code === 403) {
        LogOut();
        setNotification("Please log in once again.");
        navigator("/");
        return;
      } else if (insertResult.code === 500) {
        setNotification(insertResult.message);
        navigator("/learning");
        return;
      } else {
        setNotification(insertResult.message);
        navigator("/learning");
        return;
      }
    } catch (err) {
      setButtonLoading(false);
      setNotification("An error has occured: " + err);
    }
  };
  return (
    <>
      <TopMenu backLink="settings" showMenu={false} />
      <PageTitle dimension="small">Leave Feedback</PageTitle>

      <form className="absolute feedback-form-wrapper">
        <FormInputTextArea
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          name="feedback"
          id="feedback"
          className="w-100"
        >
          Write feedback here
        </FormInputTextArea>

        <div
          className="checkbox-wrapper"
          onClick={() => setUserAllowsContact(!userAllowsContact)}
        >
          <div className={`checkbox ${userAllowsContact ? "active" : ""}`}>
            {userAllowsContact ? "x" : ""}
          </div>
          <div className="text">It's ok to contact me about my feedback</div>
        </div>

        <CustomButton
          variant="primary"
          className="w-100 mt-2"
          onClick={handleSendFeedback}
          disabled={feedbackText.length === 0 || buttonLoading}
        >
          {buttonLoading ? "Sending feedback..." : "Send"}
        </CustomButton>
      </form>
      <AppLogoBottom />
    </>
  );
};

export default FeedbackPage;
