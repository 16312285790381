import React from "react";
import { Link, useLocation } from "react-router-dom";
const CustomLink = ({ to, ...props }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  let linkTo = to;
  if (source) {
    const isObj = typeof to === "object";
    const search = isObj ? to.search : "";
    linkTo = isObj
      ? { ...to, search: `${search ? search + "&" : "?"}source=${source}` }
      : `${to}?source=${source}`;
  }

  return <Link to={linkTo} {...props} />;
};

export default CustomLink;
