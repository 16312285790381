import useGetMascot from "../../utils/GetRandomMascot";
import CustomButton from "../Buttons";
import "./styles.css";
const ChallengeFinalizeButton = ({
  onClick,
  buttonLoading,
  mascotID,
  className,
}) => {
  const mascot = useGetMascot(mascotID);
  return (
    <div className={`finalize-challenge-wrapper ${className} `}>
      <div className="finalize-challenge-image-wrapper">
        <img
          style={{ display: "block" }}
          src={mascot}
          alt="One of BioTweak's mascots"
        />
      </div>
      <CustomButton
        variant="primary"
        className="w-100 z-top-1"
        onClick={onClick}
        disabled={buttonLoading}
      >
        {buttonLoading ? "Loading..." : "Done"}
      </CustomButton>
    </div>
  );
};

export default ChallengeFinalizeButton;
