import useGetChallengeTimeLeft from "../../utils/GetChallengeTimeLeft";

import "./styles.css";
const ChallengeTimelineBar = ({ minutesLeft, className }) => {
  const [hrsLeft, barProgress] = useGetChallengeTimeLeft(minutesLeft);

  return (
    <div className={`challenge-timeline-wrapper ${className} no-bg`}>
      <h3>{hrsLeft} h left</h3>

      <div className="progress-bar-outside">
        <div
          className="progress-bar"
          style={{ width: barProgress + "%" }}
        ></div>
      </div>

      <div className="progress-bar-times">
        <span>0 h</span>
        <span>24 h</span>
      </div>
    </div>
  );
};

export default ChallengeTimelineBar;
