// include styles.css
import "./styles.css";

import { useState, useEffect } from "react";
import BottomMenu from "../../components/BottomMenu";
import TopMenu from "../../components/TopMenu";
import ScrollContent from "../../components/ScrollContent";
import ProgressCalendar from "../../components/ProgressCalendar";
import { getCompletedEvents, getProgressCalendar } from "../../utils/API/user";
import ProgressPageInfoBubble from "../../components/ProgressPageInfoBubble";
import { useNotifications } from "../../utils/NotificationContext";
import { LogOut } from "../../utils/API/auth";
import { CalendarNotification } from "../../components/StaticNotification";
import { setNotification } from "../../utils/Notifications";
import {
  getCalendarNotification,
  markCalendarNotificationViewed,
} from "../../utils/API/interaction";
import PageTitle from "../../components/PageTitle";
import useCustomNavigate from "../../utils/CustomNavigate";

const ProgressPage = () => {
  const navigator = useCustomNavigate();

  const [calendar, setCalendar] = useState(null);
  const [calendarType, setCalendarType] = useState(5);
  const [streak, setStreak] = useState(0);
  const [noCompletedChallenges, setNoCompletedChallenges] = useState(0);
  const { addNotification } = useNotifications();
  const [staticNotification, setStaticNotification] = useState(null);

  const handleNotificationOpen = () => {
    setStaticNotification(0);
    markCalendarNotificationViewed();
    navigator("/progress/notifications");
  };

  useEffect(() => {
    (async () => {
      let newCalendar = await getProgressCalendar();
      if (newCalendar.code === 200) {
        setCalendar(newCalendar.calendar);
        setCalendarType(newCalendar.type);
        setStreak(newCalendar.currentStreak);
      } else if (newCalendar.code === 400) {
        addNotification(newCalendar.message);
        navigator("/learning");
        return;
      } else if (newCalendar.code === 403) {
        LogOut();
        addNotification("Please log in once again.");
        navigator("/");
      } else {
        addNotification(newCalendar.message);
        navigator("/learning");
      }
    })();

    (async () => {
      let newNoCompletedChallenges = await getCompletedEvents();

      if (newNoCompletedChallenges.code === 200) {
        setNoCompletedChallenges(newNoCompletedChallenges.challenges_completed);
      } else if (newNoCompletedChallenges.code === 400) {
        addNotification(newNoCompletedChallenges.message);
        navigator("/learning");
        return;
      } else if (newNoCompletedChallenges.code === 403) {
        LogOut();
        addNotification("Please log in once again.");
        navigator("/");
      } else {
        addNotification(newNoCompletedChallenges.message);
        navigator("/learning");
      }
    })();

    (async () => {
      let newNotification = await getCalendarNotification();

      if (newNotification.code === 200) {
        setStaticNotification(newNotification.notification);
      } else if (newNotification.code === 400) {
        addNotification(newNotification.message);
      } else if (newNotification.code === 403) {
        LogOut();
        setNotification("Please log in once again.");
        navigator("/");
        return;
      } else if (newNotification.code === 404) {
        setStaticNotification(0);
        return;
      } else if (newNotification.code === 500) {
        addNotification(newNotification.message);
      } else {
        addNotification(newNotification.message);
      }
    })();
  }, []);

  if (!calendar || staticNotification === null)
    return <PageTitle dimension="big">Loading...</PageTitle>;

  return (
    <>
      <TopMenu />

      <ProgressPageInfoBubble streak={streak} />

      {staticNotification && (
        <CalendarNotification
          notification={staticNotification}
          onNotificationOpen={handleNotificationOpen}
        />
      )}

      <div
        className={`absolute progress-calendar-wrapper-wrapper ${
          staticNotification ? "has-notification" : ""
        }`}
      >
        <ScrollContent
          className={` progress-calendar-wrapper `}
          scrollToBottom={calendar !== null}
        >
          <ProgressCalendar calendar={calendar} type={calendarType} />
        </ScrollContent>
        <div className=" progress-challenges-completed">
          {noCompletedChallenges} Challenge
          {noCompletedChallenges === 1 ? "" : "s"} done!
        </div>
      </div>
      <BottomMenu active="progress"></BottomMenu>
    </>
  );
};

export default ProgressPage;
