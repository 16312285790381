function capitalizeFirstLetter(str) {
  if (!str) return str;
  // Take the first letter and make it uppercase.
  const firstLetter = str.charAt(0).toUpperCase();

  // Take the rest of the string and make it lowercase.
  const restOfString = str.slice(1).toLowerCase();

  // Concatenate the first letter with the rest of the string.
  return firstLetter + restOfString;
}

function objectsAreEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1).sort();
  const keys2 = Object.keys(obj2).sort();

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let i = 0; i < keys1.length; i++) {
    if (keys1[i] !== keys2[i]) {
      return false;
    }

    if (obj1[keys1[i]] !== obj2[keys2[i]]) {
      return false;
    }
  }

  return true;
}

export { capitalizeFirstLetter, objectsAreEqual };
