import "./styles.css";

const PageTitle = ({
  dimension = "small",
  float = "center",
  children,
  subtitle = null,
  subtitle_class = "",
  variant = "",
  style = {},
  title_style = {},
  tiny_title = "",
  tiny_title_class = "",
}) => {
  return (
    <div
      className={`absolute no-bg page-title-wrapper ${dimension} ${variant} float-${float}`}
      style={style}
    >
      <h1 style={title_style}>{children}</h1>

      {subtitle && (
        <p className={`page-title-subtitle ${subtitle_class}`}>{subtitle}</p>
      )}

      {tiny_title && (
        <span className={`page-title-tiny-title ${tiny_title_class}`}>
          {tiny_title}
        </span>
      )}
    </div>
  );
};

const LoadingTitle = () => {
  return <PageTitle dimension="small-middle">Loading...</PageTitle>;
};

export default PageTitle;
export { LoadingTitle };
