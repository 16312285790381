import { useEffect, useState } from "react";
import CustomButton from "../../components/Buttons";
import "./styles.css";
const InstallPage = () => {
  const [installable, setInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const [installResult, setInstallResult] = useState(null); //0 denied // 1 accepted

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the default install prompt
      e.preventDefault();

      // Save the event for later use
      setDeferredPrompt(e);

      // Update state to notify that app can be installed
      setInstallable(true);
    });
  }, []);

  const handleInstallClick = () => {
    // Show install prompt
    if (deferredPrompt) {
      deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          setInstallResult(0);
        } else {
          setInstallResult(1);
        }
        // Clear the saved prompt since it can't be used again
        setDeferredPrompt(null);
        setInstallable(false);
      });
    }
  };

  let buttonText = "Loading...";
  if (installable) buttonText = "Install Now";
  if (installResult !== null) buttonText = "Now, open the app!";
  return (
    <>
      <img
        src="assets/images/go-install-logo.svg"
        className="absolute go-install-logo"
        alt="Logo"
      />

      <div className="absolute no-bg install-wrapper">
        <img
          src="assets/images/go-install-mascot.svg"
          className="go-install-mascot"
          alt="Mascot eating an apple - Healthy Habits!"
        />
        <h1>Welcome to BioTweak</h1>
        <h3 className="pc">
          Download the Mobile app and start exploring healthy micro-habits!
        </h3>
        <h3 className={`mobile ${installable ? "" : "install-hidden"}`}>
          Install the app directly from here
        </h3>

        <CustomButton
          variant="primary"
          onClick={handleInstallClick}
          className={`install-button ${installable ? "" : "install-hidden"}`}
          disabled={!installable || installResult !== null}
        >
          {buttonText}
        </CustomButton>

        <h3 className="mobile">Find us on:</h3>

        <div className="buttons">
          <a href="https://play.google.com/store/apps/details?id=net.biotweak.twa&pcampaignid=web_share">
            <img src="assets/images/google-play.png" alt="Google Play Link" />
          </a>
          <a href="#" style={{ display: "none" }}>
            <img src="assets/images/app-store.png" alt="App Store Link" />
          </a>
        </div>

        <div className="footer">
          <span>BioTweak 2023</span>
          <span>
            <a href="mailto: hello@biotweak.tech">hello@biotweak.tech</a>
          </span>
          <span className="mobile" onClick={() => window.location.reload()}>
            Already on the app? Tap here to refresh
          </span>
        </div>
      </div>
    </>
  );
};

export default InstallPage;
