// include styles.css
import "./styles.css";

import { useLocation } from "react-router-dom";

import TopMenu from "../../components/TopMenu";
import PageTitle, { LoadingTitle } from "../../components/PageTitle";
import useGetMascot from "../../utils/GetRandomMascot";
import { useEffect } from "react";
import { setNotification } from "../../utils/Notifications";
import ScrollContent from "../../components/ScrollContent";
import useCustomNavigate from "../../utils/CustomNavigate";

const ChallengeInfoPage = () => {
  const navigator = useCustomNavigate();
  const location = useLocation();
  const mascot = useGetMascot(location.state.info.mascot ?? 0);

  //console.log(location);
  useEffect(() => {
    if (
      !location.state ||
      !location.state.info.info ||
      (!location.state.info.mascot && location.state.info.mascot !== 0)
    ) {
      setNotification("Error loading challenge information...");
      navigator("/learning");
    }
  }, []);

  if (
    !location.state ||
    !location.state.info.info ||
    (!location.state.info.mascot && location.state.info.mascot !== 0)
  )
    return <LoadingTitle />;

  return (
    <>
      <TopMenu backLink="challenge" showMenu={false} />

      <PageTitle
        dimension="big"
        subtitle="Why do we do this?"
        subtitle_class="cursive"
      >
        INFO
      </PageTitle>

      <ScrollContent className="absolute challenge-info-wrapper">
        <p dangerouslySetInnerHTML={{ __html: location.state.info.info }}></p>
      </ScrollContent>

      <div className="absolute challenge-info-image-wrapper no-bg z-top-2">
        <img src={mascot} alt="" />
      </div>
    </>
  );
};

export default ChallengeInfoPage;
