import axios from "axios";
import { getCookie } from "../cookie";

const getTodayChallenge = async () => {
  // post a request to the user/auth path with the email in the body using axios fetch
  let response = 0;
  await axios
    .get(
      process.env.REACT_APP_API_URL + "/user/get-todays-event",

      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      const challenge = {
        event_id: res.data.event._id,
        challenge_id: res.data.event.challenge._id,
        avatar: res.data.event.challenge.avatar,
        description: res.data.event.challenge.description,
        info: res.data.event.challenge.info,
        submitted: res.data.submission !== null,
        timeLeft: res.data.timeLeft,
        submittedNow: false,
      };
      response = {
        code: 200,
        challenge: challenge,
        feedback:
          res.data.submission !== null ? res.data.submission.feedback : null,
      };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
};

const setTodayChallengeComplete = async (
  id,
  feedback = -1,
  allowNotifications = null
) => {
  // post a request to the user/auth path with the email in the body using axios fetch
  let response = 0;

  let body = {
    feedback: feedback,
  };

  if (allowNotifications !== null) {
    body.anonymously = !allowNotifications;
  }

  await axios
    .post(process.env.REACT_APP_API_URL + "/user/complete-event/" + id, body, {
      headers: {
        token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
      },
    })
    .then((res) => {
      //console.log(res.data);
      response = { code: 200, message: res.data };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
};

const getChallengeColleaguesAlsoCompleted = async (event_id) => {
  let response = 0;

  await axios
    .get(
      process.env.REACT_APP_API_URL + "/event/" + event_id + "/submissions",
      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      const colleagues = res.data.map((submission) => {
        return {
          name: submission.name + " " + submission.surname,
          id: submission._id,
        };
      });
      response = { code: 200, content: colleagues };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 403)
        response = {
          code: 403,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
};

export {
  getTodayChallenge,
  setTodayChallengeComplete,
  getChallengeColleaguesAlsoCompleted,
};
