import { useLocation } from "react-router-dom";
import { setNotification } from "../../utils/Notifications";
import { ChallengeNotificationPage } from "../../components/StaticNotification";
import PageTitle, { LoadingTitle } from "../../components/PageTitle";
import ScrollContent from "../../components/ScrollContent";
import "./styles.css";
import TopMenu from "../../components/TopMenu";
import BottomMenu from "../../components/BottomMenu";
import { useEffect } from "react";
import useCustomNavigate from "../../utils/CustomNavigate";

const ChallengeCompletedByPage = () => {
  const location = useLocation();
  const navigator = useCustomNavigate();

  useEffect(() => {
    if (!location || !location.state || !location.state.colleagues_list) {
      setNotification("Error loading page...");
      navigator("/challenge");
    }
  }, [location]);

  let backState = {};
  if (location.state.back) backState = { back: location.state.back };

  if (!location || !location.state || !location.state.colleagues_list) {
    return (
      <>
        <TopMenu
          backLink="challenge"
          backLinkState={backState}
          showMenu={false}
        />
        <LoadingTitle />
        <BottomMenu active="challenge" />
      </>
    );
  }

  const colleagues_list_jsx = location.state.colleagues_list.map(
    (colleague, index) => {
      return (
        <ChallengeNotificationPage name={colleague.name} key={colleague.id} />
      );
    }
  );

  return (
    <>
      <TopMenu
        backLink="challenge"
        backLinkState={backState}
        showMenu={false}
      />
      <PageTitle float="left">
        Completed by {location.state.colleagues_list.length} colleague
        {location.state.colleagues_list.length > 1 && "s"}!
      </PageTitle>

      <ScrollContent className="absolute completed-by-scroll-content">
        {colleagues_list_jsx}
      </ScrollContent>

      <BottomMenu active="challenge" />
    </>
  );
};

export default ChallengeCompletedByPage;
