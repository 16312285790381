import "./styles.css";
const FormRadio = ({ active, onFormChange, className = "" }) => {
  return (
    <div
      className={`form-radio no-bg ${active ? "active" : ""} ${className}`}
      onClick={onFormChange}
    >
      <div className={`circle ${active ? "active" : ""}`}>
        <img
          src={
            active
              ? "/assets/images/radio/radio-check.png"
              : "/assets/images/radio/radio-uncheck.png"
          }
          alt={`Radio button ${active ? "checked" : "unchecked"}`}
        />
      </div>
    </div>
  );
};

export default FormRadio;
