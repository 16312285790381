import TopMenu from "../../components/TopMenu";
import { useState, useMemo, useCallback, useEffect } from "react";

import "./styles.css";
import PageTitle from "../../components/PageTitle";
import FormInputText from "../../components/FormInputText";
import CustomButton from "../../components/Buttons";
import {
  getOfficeLocations,
  getUser,
  updateUserInformation,
} from "../../utils/API/user";

import { LogOut } from "../../utils/API/auth";
import { useNotifications } from "../../utils/NotificationContext";
import { capitalizeFirstLetter } from "../../utils/str";
import FormRadio from "../../components/FormRadio";
import CustomLink from "../../utils/customLink";
import useCustomNavigate from "../../utils/CustomNavigate";
import { deleteCookie, setCookie } from "../../utils/cookie";
import OneSignal from "react-onesignal";

const AccountPage = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  const [officeLocations, setOfficeLocations] = useState(null);

  const [newUserData, setNewUserData] = useState({
    name: getUser().name,
    surname: getUser().surname,
    location: getUser().location ?? "",
    locationName: getUser().locationName ?? "",
    allowNotifications: getUser().allowNotifications ?? true,
  });

  const [deviceNotificationsEnabled, setDeviceNotificationsEnabled] = useState(
    OneSignal.Notifications.permission !== false
  );
  const [
    deviceAutomaticallyBlocksNotifications,
    setDeviceAutomaticallyBlocksNotifications,
  ] = useState(false);

  const [hasModification, setHasModification] = useState(false);
  const { addNotification } = useNotifications();
  const navigator = useCustomNavigate();

  function requestNotification() {
    setCookie("attempt_request_notification_2", "false", 1);

    // calculate time it takes to deny notification, to check if it was auto-reject
    let time_init_request = new Date();

    if (!("Notification" in window)) {
      setDeviceNotificationsEnabled(false);

      setDeviceAutomaticallyBlocksNotifications(true);
      setCookie("notifications_blocked_2", "true", 30);
      // Daca device-ul nu e safari

      if (
        navigator &&
        navigator.userAgent &&
        !(navigator.userAgent.toLowerCase().indexOf("safari/") > -1)
      ) {
        addNotification("Notifications are not supported by your device.");
      }
      return;
    }

    if (Notification.permission === "granted") {
      setDeviceNotificationsEnabled(true);
      return;
    }

    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        setDeviceNotificationsEnabled(true);
        addNotification("Thank you for allowing notifications.", 10);
        deleteCookie("notifications_blocked_2");
      }

      if (permission === "denied") {
        let time_deny_request = new Date();

        if (time_deny_request.getTime() - time_init_request.getTime() > 1000) {
          addNotification("You have blocked notifications.", 10);
        } else {
          addNotification(
            "Please enable notifications in the app settings.",
            10
          );

          setDeviceAutomaticallyBlocksNotifications(true);
        }

        setCookie("notifications_blocked_2", "true", 7);
      }
    });
  }

  useEffect(() => {
    (async () => {
      let newOfficeLocations = await getOfficeLocations();

      if (newOfficeLocations.code === 200) {
        setOfficeLocations(newOfficeLocations.officeLocations);
      } else if (newOfficeLocations.code === 400) {
        addNotification(newOfficeLocations.message);
        navigator("/learning");
        return;
      } else if (newOfficeLocations.code === 403) {
        LogOut();
        addNotification("Please log in once again.");
        navigator("/");
      } else {
        addNotification(newOfficeLocations.message);
        navigator("/learning");
      }
    })();
  }, []);

  const handleSaveUserDetails = useCallback(async () => {
    setButtonLoading(true);
    try {
      let updateResult = await updateUserInformation(newUserData);

      if (updateResult !== 0) {
        setButtonLoading(false);
        addNotification(updateResult.message);
        return;
      }
      setHasModification(false);
      setButtonLoading(false);
      addNotification("User details updated successfully.");
      return;
    } catch (err) {
      setButtonLoading(false);
      addNotification("An error has occured while updating the user.");
    }
  }, [newUserData, addNotification]);

  const locationJSX = useMemo(() => {
    if (!officeLocations) {
      return <></>;
    }

    return officeLocations.map((loc) => (
      <li
        onClick={() => {
          setNewUserData({
            ...newUserData,
            location: loc.id,
            locationName: loc.name,
          });
          setHasModification(true);
        }}
        key={loc.id}
      >
        <span
          className={`radio-wrapper ${
            newUserData.location === loc.id ? "checked" : ""
          }`}
        >
          <span className="radio">
            <span className="interior"></span>
          </span>
        </span>
        {loc.name}
      </li>
    ));
  }, [officeLocations, newUserData]);

  const saveDataButton = useMemo(() => {
    if (buttonLoading) {
      return (
        <CustomButton variant="primary" className="w-100" disabled>
          Saving user data...
        </CustomButton>
      );
    }

    return (
      <CustomButton
        variant="primary"
        className="w-100 "
        onClick={handleSaveUserDetails}
        disabled={!hasModification}
      >
        Save
      </CustomButton>
    );
  }, [buttonLoading, handleSaveUserDetails, hasModification]);

  const [accountTitleClicks, setAccountTitleClicks] = useState(0);
  const onAccountTitleClick = () => {
    setAccountTitleClicks(accountTitleClicks + 1);

    if (accountTitleClicks >= 10 && process.env.REACT_APP_ALLOW_DEBUG_MODE) {
      setCookie("is_debug", "true", 1);

      addNotification("Debug mode enabled");
    }
  };

  return (
    <>
      {!showLocationSelector && (
        <TopMenu backLink="settings" showMenu={false} />
      )}

      {!showLocationSelector &&
        !deviceNotificationsEnabled &&
        !deviceAutomaticallyBlocksNotifications && (
          <img
            src="assets/images/bell.svg"
            alt="Notifications are not enabled"
            className="absolute notifications-disabled-icon"
            onClick={requestNotification}
          />
        )}
      <PageTitle dimension="small" onClick={onAccountTitleClick}>
        Account
      </PageTitle>

      <form className="absolute account-modify-form">
        <FormInputText
          name="name"
          id="name"
          onInput={(e) => {
            setNewUserData({ ...newUserData, name: e.target.value });
            setHasModification(true);
          }}
          value={newUserData.name}
        >
          First Name
        </FormInputText>
        <FormInputText
          name="surname"
          id="surname"
          onInput={(e) => {
            setNewUserData({ ...newUserData, surname: e.target.value });
            setHasModification(true);
          }}
          value={newUserData.surname}
        >
          Surname
        </FormInputText>
        <FormInputText
          name="location"
          id="location"
          onFocus={() => setShowLocationSelector(true)}
          value={capitalizeFirstLetter(newUserData.locationName)}
          autocomplete="off"
          readOnly="readonly"
        >
          Office Location
        </FormInputText>

        {saveDataButton}
      </form>

      <div className="absolute account-privacy-wrapper">
        <div className="info">
          <img src="/assets/images/lock.png" alt="lock" />
          <div className="title">Share achievements with colleagues</div>
          <div className="info">
            This does not affect your ability to see your own progress
          </div>
        </div>

        <div className="form">
          <div>
            <FormRadio
              active={newUserData.allowNotifications}
              onFormChange={() => {
                setNewUserData({
                  ...newUserData,
                  allowNotifications: !newUserData.allowNotifications,
                });
                setHasModification(true);
              }}
            />
          </div>
          <div className="info">
            Allow colleagues to see which challenge you completed
          </div>
        </div>
      </div>

      <div className="absolute account-delete-wrapper">
        <CustomLink to="/account/delete">Permanently delete account</CustomLink>
      </div>

      {showLocationSelector && (
        <>
          <div className="absolute z-top-1 location-selector-wrapper">
            <TopMenu
              backLink="back"
              showMenu={false}
              overloadBack={() => setShowLocationSelector(false)}
              className="z-top-2"
            />
            <PageTitle dimension="small">Where are you located?</PageTitle>

            <div className="absolute location-selector">
              <ul>{locationJSX}</ul>
            </div>

            <CustomButton
              variant="primary"
              className="absolute  button-save-location"
              onClick={() => setShowLocationSelector(false)}
              disabled={newUserData.location === ""}
            >
              Save
            </CustomButton>
          </div>
        </>
      )}
    </>
  );
};

export default AccountPage;
