// include styles.css
import "./styles.css";

import { useLocation } from "react-router-dom";

import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import BottomMenu from "../../components/BottomMenu";
import {
  getChallengeColleaguesAlsoCompleted,
  getTodayChallenge,
  setTodayChallengeComplete,
} from "../../utils/API/challenge";
import { LoadingTitle } from "../../components/PageTitle";
import {
  ChallengeElement,
  CompletedChallengeElement,
  CompletedChallengeElementReview,
  CompletedChallengeElementWithShare,
  PostCompletedChallengeElement,
} from "../../components/ChallengeElement";
import { setNotification } from "../../utils/Notifications";
import { LogOut } from "../../utils/API/auth";
import {
  getChallengeNotification,
  shareAchievementInformation,
} from "../../utils/API/interaction";
import { useNotifications } from "../../utils/NotificationContext";
import { getUser } from "../../utils/API/user";
import { getCurrentDayFormat } from "../../utils/time";
import useCustomNavigate from "../../utils/CustomNavigate";

const ChallengePage = () => {
  const navigator = useCustomNavigate();
  const location = useLocation();

  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [colleaguesAlsoCompleted, setColleaguesAlsoCompleted] = useState([]);
  const [challengeCompleteButtonLoading, setChallengeCompleteButtonLoading] =
    useState(false);
  const [staticNotification, setStaticNotification] = useState(null);
  const [staticNotificationClosing, setStaticNotificationClosing] =
    useState(false);

  const [showAnimation, setShowAnimation] = useState(
    localStorage.getItem("BTK-showedAnimation-" + getCurrentDayFormat()) ===
      "true"
      ? false
      : true
  );

  const onAnimationPresentClicked = () => {
    localStorage.setItem("BTK-showedAnimation-" + getCurrentDayFormat(), true);

    setTimeout(() => {
      setShowAnimation(false);
    }, 1000);
  };

  let ChallengeReviewBegin = false;
  if (location && location.state && location.state.back === "review")
    ChallengeReviewBegin = true;
  const [viewChallengeReview, setViewChallengeReview] =
    useState(ChallengeReviewBegin);

  const [feedback, setFeedback] = useState(-1);

  // this allows for manual change of allow or not notifications -
  // can be changed by the user on the ChallengeSettingsPage
  const [allowsNotifications, setAllowsNotifications] = useState(
    getUser().allowNotifications
  );

  const { addNotification } = useNotifications();

  // this is the function that gets called when the user completes the challenge
  const handleChallengeComplete = useCallback(async () => {
    setChallengeCompleteButtonLoading(true);
    try {
      let updateResult = await setTodayChallengeComplete(
        currentChallenge?.event_id,
        feedback,
        allowsNotifications
      );

      if (updateResult.code === 200) {
        setCurrentChallenge({
          ...currentChallenge,
          submitted: true,
          submittedNow: true,
          achievement: updateResult.message.achievement,
        });
      }

      return;
    } catch (err) {
      setChallengeCompleteButtonLoading(false);
      addNotification(
        "An error has occured while attempting to complete the challenge"
      );
    }
  }, [currentChallenge, feedback, addNotification, allowsNotifications]);

  const handleNotificationClose = () => {
    setStaticNotificationClosing(true);

    localStorage.removeItem(
      "BTK-challenge-notification-" + staticNotification.item_date
    );

    setTimeout(() => {
      setStaticNotification(0);
      setStaticNotificationClosing(false);
    }, 500);
  };

  const handleFeedbackClicked = (value) => {
    (async () => {
      if (!currentChallenge) return;
      if (!currentChallenge.submitted) return;

      try {
        let updateResult = await setTodayChallengeComplete(
          currentChallenge?.event_id,
          value
        );

        if (updateResult.code === 200) {
          setFeedback(updateResult.message.feedback);
        }
        return;
      } catch (err) {
        setChallengeCompleteButtonLoading(false);
        addNotification(
          "An error has occured while attempting to complete the challenge"
        );
      }
    })();
  };

  const handleShareChallenge = async (achievement_id) => {
    setCurrentChallenge({
      ...currentChallenge,
      submittedNow: false,
      achievement: null,
    });

    let sendResult = await shareAchievementInformation(achievement_id);

    if (sendResult.code === 200) {
      return true;
    } else if (sendResult.code === 400) {
      addNotification(sendResult.message);
    } else if (sendResult.code === 403) {
      LogOut();
      addNotification("Please log in once again.");
      navigator("/");
      return;
    } else if (sendResult.code === 500) {
      addNotification(sendResult.message);
    } else {
      addNotification(sendResult.message);
    }
  };

  const getColleaguesAlsoCompleted = useCallback(async (event_id) => {
    let newColleaguesAlsoCompleted = await getChallengeColleaguesAlsoCompleted(
      event_id
    );

    if (newColleaguesAlsoCompleted.code === 200) {
      setColleaguesAlsoCompleted(newColleaguesAlsoCompleted.content);
    } else if (newColleaguesAlsoCompleted.code === 400) {
      setNotification(newColleaguesAlsoCompleted.message);
      navigator("/learning");
      return;
    } else if (newColleaguesAlsoCompleted.code === 403) {
      LogOut();
      setNotification("Please log in once again.");
      navigator("/");
      return;
    } else if (newColleaguesAlsoCompleted.code === 500) {
      setNotification(newColleaguesAlsoCompleted.message);
      navigator("/learning");
      return;
    } else {
      setNotification(newColleaguesAlsoCompleted.message);
      navigator("/learning");
      return;
    }
  }, []);

  const getTodayChallengeCallback = useCallback(async () => {
    let newCurrentChallenge = await getTodayChallenge();

    if (newCurrentChallenge.code === 200) {
      setCurrentChallenge(newCurrentChallenge.challenge);

      setFeedback(newCurrentChallenge.feedback);

      const allows_notifications = localStorage.getItem(
        "BTK-" + newCurrentChallenge.challenge.event_id + "-allow-notifications"
      );
      if (allows_notifications !== null) {
        setAllowsNotifications(allows_notifications === "true");
      } else {
        setAllowsNotifications(getUser().allowNotifications);
      }
    } else if (newCurrentChallenge.code === 400) {
      setNotification(newCurrentChallenge.message);
      navigator("/learning");
      return;
    } else if (newCurrentChallenge.code === 403) {
      LogOut();
      setNotification("Please log in once again.");
      navigator("/");
      return;
    } else if (newCurrentChallenge.code === 500) {
      setNotification(newCurrentChallenge.message);
      navigator("/learning");
      return;
    } else {
      setNotification(newCurrentChallenge.message);
      navigator("/learning");
      return;
    }
  }, [navigator]);

  const getChallengeNotificationCallback = useCallback(async () => {
    let newNotification = await getChallengeNotification();

    if (newNotification.code === 200) {
      setStaticNotification(newNotification.notification);
    } else if (newNotification.code === 400) {
      setNotification(newNotification.message);
      navigator("/learning");
      return;
    } else if (newNotification.code === 403) {
      LogOut();
      setNotification("Please log in once again.");
      navigator("/");
      return;
    } else if (newNotification.code === 404) {
      setStaticNotification(0);
      return;
    } else if (newNotification.code === 500) {
      setNotification(newNotification.message);
      navigator("/learning");
      return;
    } else {
      setNotification(newNotification.message);
      navigator("/learning");
      return;
    }
  }, [navigator]);

  const hasFetched = useRef(null);

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      getTodayChallengeCallback();

      getChallengeNotificationCallback();
    }
  }, []);

  useEffect(() => {
    if (currentChallenge == null) return;
    getColleaguesAlsoCompleted(currentChallenge.event_id);
  }, [currentChallenge, getColleaguesAlsoCompleted]);

  let pageContent = useMemo(() => {
    if (currentChallenge == null || staticNotification == null)
      return (
        <>
          <LoadingTitle />
          <BottomMenu active="challenge"></BottomMenu>
        </>
      );

    if (currentChallenge.submitted === false)
      return (
        <>
          <ChallengeElement
            challenge={currentChallenge}
            onChallengeCompleted={handleChallengeComplete}
            buttonLoading={challengeCompleteButtonLoading}
            colleaguesAlsoCompleted={colleaguesAlsoCompleted}
            notification={staticNotification}
            onNotificationClose={handleNotificationClose}
            notificationClosing={staticNotificationClosing}
            showAnimation={showAnimation}
            onAnimationPresentClicked={onAnimationPresentClicked}
          />
          <BottomMenu
            active="challenge"
            include={showAnimation ? [] : ["info"]}
            challenge_info={
              currentChallenge && !showAnimation
                ? {
                    info: currentChallenge.info,
                    mascot: currentChallenge.avatar,
                  }
                : {}
            }
          ></BottomMenu>
        </>
      );

    if (currentChallenge.submitted === true && viewChallengeReview) {
      return (
        <>
          {" "}
          <CompletedChallengeElementReview
            challenge={currentChallenge}
            feedback={feedback}
            onFeedbackClicked={handleFeedbackClicked}
            onBackPressed={() => setViewChallengeReview(false)}
            colleaguesAlsoCompleted={colleaguesAlsoCompleted}
          />{" "}
          <BottomMenu
            active="challenge"
            include={["info"]}
            challenge_info={
              currentChallenge && {
                info: currentChallenge.info,
                mascot: currentChallenge.avatar,
              }
            }
            info_state={{ back: "review" }}
          ></BottomMenu>
        </>
      );
    }

    if (
      currentChallenge.submitted === true &&
      currentChallenge.submittedNow === true
    ) {
      if (currentChallenge.achievement)
        return (
          <CompletedChallengeElementWithShare
            challenge={currentChallenge}
            onShareChallenge={handleShareChallenge}
          />
        );

      return (
        <>
          <CompletedChallengeElement
            challenge={currentChallenge}
            onFeedbackClicked={handleFeedbackClicked}
            feedback={feedback}
          />
          <BottomMenu active="challenge"></BottomMenu>
        </>
      );
    }

    if (currentChallenge.submitted === true) {
      return (
        <>
          <PostCompletedChallengeElement
            onReviewPressed={() => setViewChallengeReview(true)}
          />
          <BottomMenu active="challenge"></BottomMenu>
        </>
      );
    }
  }, [
    currentChallenge,
    feedback,
    challengeCompleteButtonLoading,
    handleChallengeComplete,
    colleaguesAlsoCompleted,
    staticNotification,
    viewChallengeReview,
    staticNotificationClosing,
    showAnimation,
  ]);

  return <>{pageContent}</>;
};

export default ChallengePage;
