import { useEffect, useMemo, useState } from "react";
import BottomMenu from "../../components/BottomMenu";
import PageTitle, { LoadingTitle } from "../../components/PageTitle";
import ScrollContent from "../../components/ScrollContent";
import TopMenu from "../../components/TopMenu";
import "./styles.css";
import { setNotification } from "../../utils/Notifications";
import { getAllCongrats } from "../../utils/API/interaction";
import { LogOut } from "../../utils/API/auth";
import { CalendarNotificationOnPage } from "../../components/StaticNotification";
import { useNotifications } from "../../utils/NotificationContext";
import useCustomNavigate from "../../utils/CustomNavigate";

const UserCongratulationsPage = () => {
  const [userNotifications, setUserNotifications] = useState(null);
  const { addNotification } = useNotifications();
  const navigator = useCustomNavigate();

  useEffect(() => {
    (async () => {
      let userNotifications = await getAllCongrats();

      if (userNotifications.code === 200) {
        setUserNotifications(userNotifications.notifications);
      } else if (userNotifications.code === 400) {
        addNotification(userNotifications.message);
      } else if (userNotifications.code === 403) {
        LogOut();
        setNotification("Please log in once again.");
        navigator("/");
        return;
      } else if (userNotifications.code === 404) {
      } else if (userNotifications.code === 500) {
        addNotification(userNotifications.message);
      } else {
        addNotification(userNotifications.message);
      }
    })();
  }, []);

  const notificationJSX = useMemo(() => {
    return userNotifications?.map((notification, index) => (
      <CalendarNotificationOnPage key={index} notification={notification} />
    ));
  }, [userNotifications]);

  if (!userNotifications)
    return (
      <>
        <TopMenu backLink="progress" showMenu={false} />
        <LoadingTitle />
        <BottomMenu active="progress" />
      </>
    );
  return (
    <>
      <TopMenu backLink="progress" showMenu={false} />
      <PageTitle dimension="small" float="left">
        Celebrations!{" "}
        <img
          src="/assets/images/confetti.png"
          alt=""
          style={{ width: "25px" }}
        />
      </PageTitle>

      <ScrollContent className="absolute calendar-notifications-scroll-content">
        {notificationJSX}
      </ScrollContent>

      <BottomMenu active="progress" />
    </>
  );
};

export default UserCongratulationsPage;
