import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getLearningArticle } from "../../utils/API/learn";
import PageTitle from "../../components/PageTitle";
import TopMenu from "../../components/TopMenu";
import ScrollContent from "../../components/ScrollContent";

import "./styles.css";
import { LogOut } from "../../utils/API/auth";
import { setNotification } from "../../utils/Notifications";
import { useNotifications } from "../../utils/NotificationContext";
import useCustomNavigate from "../../utils/CustomNavigate";

const LearnArticlePage = () => {
  const location = useLocation();
  const [learnContent, setLearnContent] = useState(null);
  const { addNotification } = useNotifications();
  const navigator = useCustomNavigate();

  const { id } = useParams();

  let backLink = "learning";
  if (location && location.state && location.state.back)
    backLink = location.state.back;

  useEffect(() => {
    (async () => {
      try {
        let newLearningContent = await getLearningArticle(id);

        if (newLearningContent.code === 200) {
          setLearnContent(newLearningContent.article);
        } else if (newLearningContent.code === 400) {
          addNotification(newLearningContent.message);
        } else if (newLearningContent.code === 403) {
          LogOut();
          setNotification("Please log in once again.");
          navigator("/");
          return;
        } else if (newLearningContent.code === 404) {
          return;
        } else if (newLearningContent.code === 500) {
          addNotification(newLearningContent.message);
        } else {
          addNotification(newLearningContent.message);
        }

        return;
      } catch (err) {
        addNotification("An error has occured while updating the user.");
      }
    })();
  }, [id]);

  if (learnContent == null) {
    return (
      <>
        <TopMenu backLink={backLink} showMenu={false} />
        <PageTitle dimension="big">Loading...</PageTitle>
      </>
    );
  }

  return (
    <>
      <TopMenu backLink={backLink} showMenu={false} />
      <ScrollContent className="absolute learn-article-wrapper">
        <img
          crossOrigin="anonymous"
          src={learnContent.thumbnail}
          alt={learnContent.title}
        />

        <h1>{learnContent.title}</h1>
        {Array.isArray(learnContent.content) ? (
          learnContent.content.map((e, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: e }}></p>
          ))
        ) : (
          <p dangerouslySetInnerHTML={{ __html: learnContent.content }}></p>
        )}
      </ScrollContent>
    </>
  );
};

export default LearnArticlePage;
