import { useEffect, useState } from "react";
import { getCompletedEvents } from "../../utils/API/user";
import ChallengeCompletedRating from "../ChallengeCompletedRating";
import ChallengeFinalizeButton from "../ChallengeFinalizeButton";
import ChallengeTimelineBar from "../ChallengeTimelineBar";
import PageTitle from "../PageTitle";
import TopMenu from "../TopMenu";

import "./styles.css";
import { setNotification } from "../../utils/Notifications";
import { LogOut } from "../../utils/API/auth";
import { ChallengeNotification } from "../StaticNotification";
import ScrollContent from "../ScrollContent";
import LearnContentElement from "../LearnContentElement";
import CustomButton from "../Buttons";
import { useNotifications } from "../../utils/NotificationContext";
import { getLearningArticles } from "../../utils/API/learn";
import CustomLink from "../../utils/customLink";
import useCustomNavigate from "../../utils/CustomNavigate";
const ChallengeElement = ({
  challenge,
  onChallengeCompleted,
  buttonLoading,
  colleaguesAlsoCompleted,
  notification,
  onNotificationClose,
  notificationClosing,
  showAnimation,
  onAnimationPresentClicked,
}) => {
  const navigator = useCustomNavigate();
  const handleSettingsClick = () => {
    navigator("/challenge/settings", {
      state: {
        challengeID: challenge.event_id,
      },
    });
  };

  const showSettings =
    !showAnimation && (notification === 0 || notificationClosing);

  const showNotification = !showAnimation && notification !== 0;

  const addHasNotification =
    notification !== 0 && !notificationClosing && !showAnimation;

  const [animationPresentClicked, setAnimationPresentClicked] = useState(false);

  const [addAnimationClassToChallenge] = useState(showAnimation);

  return (
    <>
      {showSettings ? (
        <div
          className={`absolute challenge-settings no-bg ${
            notificationClosing ? "appearing" : ""
          } `}
          onClick={handleSettingsClick}
        >
          <img src="/assets/images/settings.svg" alt="Settings bar" />
        </div>
      ) : null}

      {showNotification ? (
        <ChallengeNotification
          notification={notification}
          onNotificationClose={onNotificationClose}
          notificationClosing={notificationClosing}
        />
      ) : null}

      <div
        className={`absolute challenge-wrapper no-bg ${
          addHasNotification ? "has_notification" : ""
        }`}
      >
        <ChallengeTimelineBar
          minutesLeft={challenge.timeLeft}
          className={addHasNotification ? "has_notification" : ""}
        />

        {showAnimation ? (
          <div
            className={`absolute challenge-animation-wrapper no-bg ${
              animationPresentClicked ? "fading" : "shaking"
            }
            `}
            onClick={() => {
              setAnimationPresentClicked(true);
              onAnimationPresentClicked();
            }}
          >
            <img
              className="top"
              src="/assets/images/animation-present/cadou_top.svg"
              alt=""
            />
            <img
              className="base"
              src="/assets/images/animation-present/cadou_baza.svg"
              alt=""
            />
            <img
              className="hidden"
              src="/assets/images/animation-present/cerc_dark.svg"
              alt=""
            />
            <img
              className="hidden"
              src="/assets/images/animation-present/cerc_dark.svg"
              alt=""
            />
            <img
              className="hidden"
              src="/assets/images/animation-present/cerc.svg"
              alt=""
            />
            <img
              className="hidden"
              src="/assets/images/animation-present/cerc.svg"
              alt=""
            />
            <img
              className="hidden"
              src="/assets/images/animation-present/confetti_albastru.svg"
              alt=""
            />
            <img
              className="hidden"
              src="/assets/images/animation-present/confetti_galben.svg"
              alt=""
            />
            <img
              className="hidden"
              src="/assets/images/animation-present/confetti_galben.svg"
              alt=""
            />
            <img
              className="hidden"
              src="/assets/images/animation-present/confetti_rosu.svg"
              alt=""
            />
            <img
              className="hidden"
              src="/assets/images/animation-present/confetti_rosu.svg"
              alt=""
            />
            <img
              className="hidden"
              src="/assets/images/animation-present/confetti_verde.svg"
              alt=""
            />
          </div>
        ) : (
          <>
            <p
              className={`description ${
                addAnimationClassToChallenge ? "challenge-page-bounce-in" : ""
              }`}
            >
              {challenge.description}
            </p>

            <ChallengeFinalizeButton
              onClick={onChallengeCompleted}
              buttonLoading={buttonLoading}
              mascotID={challenge.avatar}
              className={`${addHasNotification ? "has_notification" : ""}  ${
                addAnimationClassToChallenge ? "challenge-page-bounce-in" : ""
              }`}
            />

            {colleaguesAlsoCompleted.length ? (
              <div
                className={`completed-by  ${
                  addAnimationClassToChallenge ? "challenge-page-bounce-in" : ""
                }`}
              >
                Completed by {colleaguesAlsoCompleted[0].name}{" "}
                {colleaguesAlsoCompleted.length > 1 ? (
                  <CustomLink
                    to="/challenge/completed-by"
                    state={{ colleagues_list: colleaguesAlsoCompleted }}
                  >
                    and {colleaguesAlsoCompleted.length - 1} more colleague
                    {colleaguesAlsoCompleted.length > 2 && "s"}
                  </CustomLink>
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

const CompletedChallengeElementWithShare = ({
  challenge,
  onShareChallenge,
}) => {
  const [challengesCompleted, setChallengesCompleted] = useState(null);
  const navigator = useCustomNavigate();
  useEffect(() => {
    (async () => {
      let newChallengesCompleted = await getCompletedEvents();

      if (newChallengesCompleted.code === 200) {
        setChallengesCompleted(newChallengesCompleted.challenges_completed);
      } else if (newChallengesCompleted.code === 400) {
        setNotification(newChallengesCompleted.message);
        navigator("/learning");
        return;
      } else if (newChallengesCompleted.code === 403) {
        LogOut();
        setNotification("Please log in once again.");
        navigator("/");
        return;
      } else if (newChallengesCompleted.code === 500) {
        setNotification(newChallengesCompleted.message);
        navigator("/learning");
        return;
      } else {
        setNotification(newChallengesCompleted.message);
        navigator("/learning");
        return;
      }
    })();
  }, []);

  return (
    <>
      <TopMenu
        menuImageName="clear_small"
        menuLink="progress"
        backLink=""
      ></TopMenu>

      <div className="absolute challenge-completed-variant-confetti">
        <img src="/assets/images/confetti-big.png" alt="" />
      </div>

      <PageTitle dimension="big-middle" variant={"blue"}>
        {!challengesCompleted
          ? `Challenge is done!`
          : `${challengesCompleted} Challenges Done!`}
      </PageTitle>

      <div className="absolute challenge-completed-variant-share">
        <h3>Share achievement with colleagues</h3>
        <CustomButton
          onClick={() => {
            onShareChallenge(challenge.achievement._id);
          }}
          style={{ width: "60%" }}
        >
          Share
        </CustomButton>
      </div>

      <div className="absolute challenge-completed-variant-mascot-bottom no-bg">
        <img src="/assets/images/mascot-apple.png" alt="" />
      </div>
    </>
  );
};

const CompletedChallengeElement = ({
  challenge,
  onFeedbackClicked,
  feedback,
}) => {
  const [challengesCompleted, setChallengesCompleted] = useState(null);

  useEffect(() => {
    (async () => {
      let newChallengesCompleted = await getCompletedEvents();

      if (newChallengesCompleted.code === 200) {
        setChallengesCompleted(newChallengesCompleted.challenges_completed);
      } else if (newChallengesCompleted.code === 400) {
        setNotification(newChallengesCompleted.message);
        navigator("/learning");
        return;
      } else if (newChallengesCompleted.code === 403) {
        LogOut();
        setNotification("Please log in once again.");
        navigator("/");
        return;
      } else if (newChallengesCompleted.code === 500) {
        setNotification(newChallengesCompleted.message);
        navigator("/learning");
        return;
      } else {
        setNotification(newChallengesCompleted.message);
        navigator("/learning");
        return;
      }
    })();
  }, []);
  return (
    <>
      <TopMenu showMenu={false} backLink="progress"></TopMenu>

      <div className="absolute challenge-completed-confetti-wrapper">
        <img src="/assets/images/confetti.png" alt="" />
      </div>

      <PageTitle
        dimension="big-middle"
        //subtitle={`${getUser().challenges_completed} Challenges Done!`}
        subtitle={
          !challengesCompleted
            ? `Challenge is done!`
            : `${challengesCompleted} Challenges Done!`
        }
        subtitle_class="big"
      >
        GREAT JOB!
      </PageTitle>

      <div className="absolute challenge-completed-check-wrapper">
        <div>
          <img src="/assets/images/check.png" alt="" />
        </div>
      </div>

      <ChallengeCompletedRating
        challenge={challenge}
        onFeedbackClicked={onFeedbackClicked}
        feedback={feedback}
      />

      <div className="absolute challenge-completed-invite-wrapper">
        <p>Come back for tommorow's challenge!</p>
      </div>
    </>
  );
};

const CompletedChallengeElementReview = ({
  challenge,
  onFeedbackClicked,
  feedback,
  onBackPressed,
  colleaguesAlsoCompleted,
}) => {
  return (
    <>
      <TopMenu
        showMenu={false}
        overloadBack={onBackPressed}
        backLink="back"
      ></TopMenu>

      <div className="absolute challenge-review-check-wrapper ">
        <div>
          <img src="/assets/images/check.png" alt="" />
        </div>
      </div>

      <div className="absolute challenge-review-info">
        <div className="info">{challenge.description}</div>
        {colleaguesAlsoCompleted.length ? (
          <div className="completed-by">
            Completed by {colleaguesAlsoCompleted[0].name}{" "}
            {colleaguesAlsoCompleted.length > 1 && (
              <CustomLink
                to="/challenge/completed-by"
                state={{
                  colleagues_list: colleaguesAlsoCompleted,
                  back: "review",
                }}
              >
                and {colleaguesAlsoCompleted.length - 1} more colleague
                {colleaguesAlsoCompleted.length > 2 && "s"}
              </CustomLink>
            )}
          </div>
        ) : null}
      </div>

      <ChallengeCompletedRating
        challenge={challenge}
        onFeedbackClicked={onFeedbackClicked}
        feedback={feedback}
      />
    </>
  );
};

const PostCompletedChallengeElement = ({ onReviewPressed }) => {
  const [learningContent, setLearningContent] = useState(null);
  const [challengesCompleted, setChallengesCompleted] = useState(null);

  const { addNotification } = useNotifications();

  useEffect(() => {
    (async () => {
      let newChallengesCompleted = await getCompletedEvents();

      if (newChallengesCompleted.code === 200) {
        setChallengesCompleted(newChallengesCompleted.challenges_completed);
      } else if (newChallengesCompleted.code === 400) {
        setNotification(newChallengesCompleted.message);
        navigator("/learning");
        return;
      } else if (newChallengesCompleted.code === 403) {
        LogOut();
        setNotification("Please log in once again.");
        navigator("/");
        return;
      } else if (newChallengesCompleted.code === 500) {
        setNotification(newChallengesCompleted.message);
        navigator("/learning");
        return;
      } else {
        setNotification(newChallengesCompleted.message);
        navigator("/learning");
        return;
      }
    })();

    (async () => {
      try {
        let newLearningContent = await getLearningArticles(2);

        if (newLearningContent.code === 200) {
          setLearningContent(newLearningContent.articles);
        } else if (newLearningContent.code === 400) {
          addNotification(newLearningContent.message);
        } else if (newLearningContent.code === 403) {
          LogOut();
          setNotification("Please log in once again.");
          navigator("/");
          return;
        } else if (newLearningContent.code === 404) {
          return;
        } else if (newLearningContent.code === 500) {
          addNotification(newLearningContent.message);
        } else {
          addNotification(newLearningContent.message);
        }

        return;
      } catch (err) {
        addNotification("An error has occured while updating the user.");
      }
    })();
  }, [addNotification]);

  const learn_jsx =
    learningContent == null
      ? null
      : learningContent.map((el, i) => {
          return (
            <LearnContentElement key={i} element={el} backLink={"challenge"} />
          );
        });
  return (
    <>
      <PageTitle dimension="small" float="left">
        {!challengesCompleted
          ? `Congratulations!`
          : `${challengesCompleted} challenges done!`}
      </PageTitle>

      <div className="absolute challenge-completed-check-wrapper post-page">
        <div>
          <img src="/assets/images/check.png" alt="" />
        </div>
      </div>

      <ScrollContent className="absolute challenge-post-completed-scroll-content">
        <div className="review" onClick={onReviewPressed}>
          Review
        </div>
        <div className="heading-2">
          Come back to find out tommorow's challenge
        </div>
        <div className="hr"></div>
        <div className="logo">
          <img src="/assets/images/head-logo.png" alt="" />
          <br />
          Learn more about micro-health:
        </div>
        {learn_jsx}
      </ScrollContent>
    </>
  );
};
export {
  ChallengeElement,
  CompletedChallengeElement,
  CompletedChallengeElementWithShare,
  PostCompletedChallengeElement,
  CompletedChallengeElementReview,
};
