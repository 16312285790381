import "./styles.css";

const FormInputText = ({
  children,
  name = "",
  id = "",
  className = "",
  onChange = () => {},
  onInput = () => {},
  onFocus = () => {},
  value = "",
  autocomplete = "on",
  style = {},
  readonly = false,
}) => {
  return (
    <input
      type="text"
      name={name ? name : children}
      id={id ? id : children}
      className={`form-input-text ${className}`}
      placeholder={children}
      onChange={onChange}
      onInput={onInput}
      onFocus={onFocus}
      value={value}
      autoComplete={autocomplete}
      style={style}
      autoCorrect="off"
      autoCapitalize="none"
      readOnly={readonly}
    />
  );
};

export default FormInputText;
