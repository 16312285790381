import useCustomNavigate from "../../utils/CustomNavigate";
import "./styles.css";

const LearnContentElement = ({ element, backLink = "learning" }) => {
  let navigator = useCustomNavigate();
  const handleClick = () => {
    navigator("/learning/" + element.id, { state: { back: backLink } });
  };
  return (
    <div className="learn-content-element-wrapper" onClick={handleClick}>
      <img
        crossOrigin="anonymous"
        src={element.thumbnail}
        alt={element.title}
      />
      <p>{element.title}</p>
    </div>
  );
};

export default LearnContentElement;
