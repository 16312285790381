import useCustomNavigate from "../../utils/CustomNavigate";
import "./styles.css";

const BottomMenuItem = ({
  imageName = "",
  active = false,
  children,
  link = "",
  challenge_info = "",
  linkState = {},
  extension = "svg",
}) => {
  const navigator = useCustomNavigate();

  const handleClick = () => {
    if (!active) {
      navigator("/" + (link ? link : imageName), {
        state: { info: challenge_info, back: linkState.back },
      });
    }
  };
  return (
    <div
      className="menu-item no-bg d-flex flex-column align-items-center justify-content-center"
      onClick={handleClick}
    >
      <img
        src={`/assets/images/${imageName}.${extension}`}
        alt={`${children} menu icon`}
        className={`${active ? "active" : ""}`}
      />
      <p className={`${active ? "active" : ""}`}>{children}</p>
    </div>
  );
};

export default BottomMenuItem;
