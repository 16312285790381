import { useLocation } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { useState } from "react";
import CustomButton from "../../components/Buttons";
import FormInputText from "../../components/FormInputText";

import "./styles.css";
import AppLogoBottom from "../../components/AppLogoBottom";
import { handleRequestCode } from "../../utils/API/auth";
import { setNotification } from "../../utils/Notifications";
import { useNotifications } from "../../utils/NotificationContext";
import TopMenu from "../../components/TopMenu";
import CustomLink from "../../utils/customLink";
import useCustomNavigate from "../../utils/CustomNavigate";

const RegisterPage = () => {
  const { addNotification } = useNotifications();

  const navigator = useCustomNavigate();
  const location = useLocation();

  const [newUserData, setNewUserData] = useState({
    name: "",
    surname: "",
    email: location.state?.pre_completed_email || "",
    location: "",
    accepts_tc: false,
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSendCode = async (e) => {
    setButtonLoading(true);
    try {
      let requestResult = await handleRequestCode(newUserData.email);

      if (requestResult.code !== 200) {
        setButtonLoading(false);

        addNotification(requestResult.message);
        return;
      }

      setButtonLoading(false);

      if (requestResult.type === "register") {
        if (requestResult.officeLocations.length === 1) {
          addNotification("Welcome! Check your e-mail to register!");
          navigator(`/hello/verify`, {
            state: {
              userData: {
                ...newUserData,
                location: requestResult.officeLocations[0].id,
              },
              type: "register",
              email: newUserData.email,
            },
          });
          return;
        }

        navigator(`/register/location`, {
          state: {
            userData: newUserData,
            type: "register",
            email: newUserData.email,
            officeLocations: requestResult.officeLocations,
          },
        });
        return;
      }

      if (requestResult.type === "login") {
        setNotification(
          "You already have an account. Check the e-mail to log in!"
        );
        navigator(`/hello/verify`, {
          state: { type: "login", email: newUserData.email },
        });
        return;
      }

      setNotification("An error has occured...");
      navigator("/");

      return;
    } catch (err) {
      setButtonLoading(false);
      addNotification(err);
    }
  };

  const saveDataButton = buttonLoading ? (
    <CustomButton variant="primary" className="w-100" disabled>
      Sending e-mail...
    </CustomButton>
  ) : (
    <CustomButton
      variant="primary"
      className="w-100 "
      onClick={handleSendCode}
      disabled={
        newUserData.name.trim() === "" ||
        newUserData.surname.trim() === "" ||
        newUserData.email === "" ||
        newUserData.accepts_tc === false
      }
    >
      Verify E-mail
    </CustomButton>
  );

  return (
    <>
      <TopMenu backLink="hello" showMenu={false} />
      <PageTitle dimension="small">Nice to meet you!</PageTitle>

      <form className="absolute account-register-form">
        <FormInputText
          name="name"
          id="name"
          onInput={(e) =>
            setNewUserData({ ...newUserData, name: e.target.value })
          }
          value={newUserData.name}
        >
          First Name
        </FormInputText>
        <FormInputText
          name="surname"
          id="surname"
          onInput={(e) =>
            setNewUserData({ ...newUserData, surname: e.target.value })
          }
          value={newUserData.surname}
        >
          Surname
        </FormInputText>
        <FormInputText
          name="email"
          id="email"
          onInput={(e) =>
            setNewUserData({ ...newUserData, email: e.target.value })
          }
          value={newUserData.email}
        >
          E-mail
        </FormInputText>

        <div
          className="read-tc-wrapper mt-5"
          onClick={() =>
            setNewUserData({
              ...newUserData,
              accepts_tc: !newUserData.accepts_tc,
            })
          }
        >
          <div className={`checkbox ${newUserData.accepts_tc ? "active" : ""}`}>
            {newUserData.accepts_tc ? "x" : ""}
          </div>
          <div className="text">
            I agree to the{" "}
            <CustomLink
              to={{
                pathname: "/about/tc",
              }}
            >
              Terms and Conditions
            </CustomLink>
          </div>
        </div>
        {saveDataButton}
      </form>

      <AppLogoBottom />
    </>
  );
};

export default RegisterPage;
