import { useLocation } from "react-router-dom";
import TopMenu from "../../components/TopMenu";
import AppLogoBottom from "../../components/AppLogoBottom";

import "./styles.css";
import { LogOut } from "../../utils/API/auth";
import CustomLink from "../../utils/customLink";
import useCustomNavigate from "../../utils/CustomNavigate";

const SettingsPage = () => {
  const navigator = useCustomNavigate();
  const handleLogOut = () => {
    LogOut();
    navigator("/");
  };

  let backLink = "challenge";

  const location = useLocation();
  if (location && location.state && location.state.back)
    backLink = location.state.back;

  return (
    <>
      <TopMenu backLink={backLink} showMenu={false} />

      <div className="absolute settings-items-wrapper">
        <CustomLink to="/about">About</CustomLink>
        <CustomLink to="/feedback">Live Feedback</CustomLink>
        <CustomLink to="/about/tc">Terms and Conditions</CustomLink>
        <CustomLink to="/account">Account</CustomLink>
        <p onClick={handleLogOut}>Log Out</p>
      </div>

      <AppLogoBottom />
    </>
  );
};

export default SettingsPage;
