import { useNotifications } from "../../utils/NotificationContext";
import ScrollContent from "../../components/ScrollContent";

import "./styles.css";
import TopMenu from "../../components/TopMenu";
import { getLegalInformation } from "../../utils/API/interaction";
import { useEffect, useState } from "react";
import { LoadingTitle } from "../../components/PageTitle";
import useCustomNavigate from "../../utils/CustomNavigate";
const LegalPage = () => {
  const { addNotification } = useNotifications();
  const navigator = useCustomNavigate();
  const [legalInformation, setLegalInformation] = useState(null);

  useEffect(() => {
    (async () => {
      let newLegalInformation = await getLegalInformation([
        "GDPR",
        "TERMS_CONDITIONS",
      ]);

      if (newLegalInformation.code === 200) {
        setLegalInformation(newLegalInformation.data);
        return;
      } else {
        addNotification(newLegalInformation.message);
        navigator("/home");
      }
    })();
  }, []);

  if (!legalInformation)
    return (
      <>
        <TopMenu backLink="settings" showMenu={false} /> <LoadingTitle />
      </>
    );
  return (
    <>
      <TopMenu backLink="settings" showMenu={false} />
      <ScrollContent className="absolute legal-wrapper">
        {legalInformation.map((legal, index) => (
          <div key={legal.key}>
            <h2>{legal.title}</h2>
            {legal.text.map((p, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: p }}></p>
            ))}
          </div>
        ))}
      </ScrollContent>
    </>
  );
};

export default LegalPage;
