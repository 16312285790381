function isNextWeekday(isoDate) {
  // Explicitly set the time zone to UTC
  const targetDate = new Date(`${isoDate}T00:00:00Z`);

  // Get the current date and time in UTC
  const currentDate = new Date();

  // Normalize both dates to midnight UTC for comparison
  currentDate.setUTCHours(0, 0, 0, 0);
  targetDate.setUTCHours(0, 0, 0, 0);

  // Calculate the difference in days
  const diffInMilliseconds = targetDate.getTime() - currentDate.getTime();
  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

  // Check if the target date is a weekend (Saturday=6, Sunday=0)
  if (targetDate.getUTCDay() === 0 || targetDate.getUTCDay() === 6) {
    return false;
  }

  // Check if the difference corresponds to the next weekday
  const currentDayOfWeek = currentDate.getUTCDay();
  if (currentDayOfWeek === 5 && diffInDays === 3) {
    // Current day is Friday
    return true;
  } else if (currentDayOfWeek === 6 && diffInDays === 2) {
    // Current day is Saturday
    return true;
  } else if (
    diffInDays === 1 &&
    currentDayOfWeek !== 5 &&
    currentDayOfWeek !== 6
  ) {
    // Any other weekday
    return true;
  }

  return false;
}

function isNextDay(isoDate) {
  // Explicitly set the time zone to UTC
  const targetDate = new Date(`${isoDate}T00:00:00Z`);

  // Get the current date and time in UTC
  const currentDate = new Date();

  // Normalize both dates to midnight UTC for comparison
  currentDate.setUTCHours(0, 0, 0, 0);
  targetDate.setUTCHours(0, 0, 0, 0);

  // Calculate the difference in days
  const diffInMilliseconds = targetDate.getTime() - currentDate.getTime();
  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

  // Check if the difference corresponds to the next day
  return diffInDays === 1;
}

/**
 * Generate a random array of consecutive weekdays in UTC, starting from a Monday
 * and ending on a Friday, with the current date around the middle.
 * @returns {Array} An array of Date objects in ISO string format.
 */
function generateRandomWeekdays() {
  // Generate a random number between 50 and 100
  const numDates = Math.floor(Math.random() * 51) + 50;

  // Get the current date and time in UTC
  const currentDate = new Date();

  // Normalize to midnight UTC
  currentDate.setUTCHours(0, 0, 0, 0);

  // Find the closest past Monday from the current date
  const dayOfWeek = currentDate.getUTCDay();
  const diffToLastMonday = (dayOfWeek - 1 + 7) % 7;
  currentDate.setUTCDate(currentDate.getUTCDate() - diffToLastMonday);

  // Initialize the array to store the dates
  const dates = [];

  // Generate dates starting from the closest past Monday
  let tempDate = new Date(currentDate);
  for (let i = 0; i < numDates; i++) {
    // Add the date to the array
    dates.push(tempDate.toISOString().split("T")[0]);

    // Move to the next day
    tempDate.setUTCDate(tempDate.getUTCDate() + 1);

    // Skip weekends (Saturday=6, Sunday=0)
    const tempWeekday = tempDate.getUTCDay();
    if (tempWeekday === 0 || tempWeekday === 6) {
      tempDate.setUTCDate(tempDate.getUTCDate() + 2);
    }
  }

  return dates;
}

function getNextFriday(fromDate) {
  let nextFriday = new Date(fromDate);
  nextFriday.setDate(nextFriday.getDate() + (12 - nextFriday.getDay()));
  return nextFriday;
}

function getNextSunday(fromDate) {
  let nextSunday = new Date(fromDate);
  nextSunday.setDate(nextSunday.getDate() + (7 - nextSunday.getDay()));
  return nextSunday;
}

// Function to get Monday that is 3 months in the past from a given date
function getPastMonday(fromDate, months = 3) {
  let pastMonday = new Date(fromDate);
  pastMonday.setMonth(pastMonday.getMonth() - months);
  pastMonday.setDate(pastMonday.getDate() - ((pastMonday.getDay() + 6) % 7));
  return pastMonday;
}

function splitIntoWeeks(array, dim = 5) {
  const weeks = [];
  for (let i = 0; i < array.length; i += dim) {
    weeks.push(array.slice(i, i + dim));
  }
  return weeks;
}

// Function to flatten an array of arrays
function flattenWeeks(weeks) {
  return weeks.reduce((acc, week) => acc.concat(week), []);
}

// Function to generate list of dates from start to end, excluding weekends
function generateDateList(startDate, endDate, challenges_list) {
  let currentDate = new Date(startDate);
  let dates = [];
  let noCompletedChallenges = 0;

  while (currentDate <= endDate) {
    // Skip weekends (Saturday = 6, Sunday = 0)
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      let isoDate = currentDate.toISOString().split("T")[0];
      dates.push({
        id: null,
        completed: false,
        streak: 0,
        high_streak: false,
        high_streak_end: false,
        feedback: false,
        date: isoDate,

        day: new Date(isoDate).getUTCDay(),
      });
    }
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  challenges_list.forEach((challenge) => {
    let splitDate = challenge.date.split("T")[0];

    if (challenge.completed.length) {
      noCompletedChallenges++;
    }

    let found_index = dates.findIndex((d) => d.date === splitDate);

    if (found_index !== -1) {
      dates[found_index] = {
        id: challenge._id,
        completed: challenge.completed.length ? true : false,
        streak: challenge.completed.length ? challenge.completed[0].streak : 0,
        high_streak: false,
        high_streak_end: false,
        date: splitDate,
        day: new Date(splitDate).getUTCDay(),
        feedback:
          challenge.completed.length && challenge.completed[0].feedback !== -1
            ? challenge.completed[0].feedback
            : false,
      };
    }
  });

  let highestStreak = -Infinity; // Start with the lowest possible value
  let indexOfHighestStreak = -1; // Initialize to -1 to indicate "not found"

  // Loop through the array to find the highest streak and its index
  for (let i = 0; i < dates.length; i++) {
    const el = dates[i];
    // Check if the "streak" field exists and if it's greater than the current highest streak
    if (el.hasOwnProperty("streak") && el.streak > highestStreak) {
      highestStreak = el.streak;
      indexOfHighestStreak = i;
    }
  }

  // If the highest streak was found, set those elements to have the highest streak
  if (indexOfHighestStreak !== -1) {
    dates[indexOfHighestStreak].high_streak_end = true;
    let it = indexOfHighestStreak;
    while (dates[it].streak > 0) {
      dates[it].high_streak = true;
      it--;
    }
  }

  // find the current highest streak
  let today = new Date();
  today = today.toISOString().split("T")[0];

  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday = yesterday.toISOString().split("T")[0];

  let currentChallenge = dates.find((d) => d.date === today);

  if (!currentChallenge || !currentChallenge.completed) {
    currentChallenge = dates.find((d) => d.date === yesterday);
  }

  let currentStreak = 0;
  if (currentChallenge && currentChallenge.completed) {
    currentStreak = currentChallenge.streak;
  }

  let weeks = splitIntoWeeks(dates);

  let reversedWeeks = weeks.reverse();
  dates = flattenWeeks(reversedWeeks);

  return [dates, currentStreak, noCompletedChallenges];
}

function generateDateListFull(startDate, endDate, challenges_list) {
  let currentDate = new Date(startDate);
  let dates = [];
  let noCompletedChallenges = 0;

  while (currentDate <= endDate) {
    let isoDate = currentDate.toISOString().split("T")[0];
    dates.push({
      id: null,
      completed: false,
      streak: 0,
      high_streak: false,
      high_streak_end: false,
      date: isoDate,

      feedback: false,

      day: new Date(isoDate).getUTCDay(),
    });

    // Move to the next day
    currentDate = new Date(currentDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  challenges_list.forEach((challenge) => {
    let splitDate = challenge.date.split("T")[0];

    if (challenge.completed.length) {
      noCompletedChallenges++;
    }

    let found_index = dates.findIndex((d) => d.date === splitDate);

    if (found_index !== -1) {
      dates[found_index] = {
        id: challenge._id,
        completed: challenge.completed.length ? true : false,
        streak: challenge.completed.length ? challenge.completed[0].streak : 0,
        feedback: challenge.completed.length
          ? challenge.completed[0].feedback
          : false,
        high_streak: false,
        high_streak_end: false,
        date: splitDate,
        day: new Date(splitDate).getUTCDay(),
      };
    }
  });

  let highestStreak = -Infinity; // Start with the lowest possible value
  let indexOfHighestStreak = -1; // Initialize to -1 to indicate "not found"

  // Loop through the array to find the highest streak and its index
  for (let i = 0; i < dates.length; i++) {
    const el = dates[i];
    // Check if the "streak" field exists and if it's greater than the current highest streak
    if (el.hasOwnProperty("streak") && el.streak > highestStreak) {
      highestStreak = el.streak;
      indexOfHighestStreak = i;
    }
  }

  // If the highest streak was found, set those elements to have the highest streak
  if (indexOfHighestStreak !== -1) {
    dates[indexOfHighestStreak].high_streak_end = true;
    let it = indexOfHighestStreak;
    while (dates[it].streak > 0) {
      dates[it].high_streak = true;
      it--;
    }
  }

  // find the current highest streak
  let today = new Date();
  today = today.toISOString().split("T")[0];

  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday = yesterday.toISOString().split("T")[0];

  let currentChallenge = dates.find((d) => d.date === today);

  if (!currentChallenge || !currentChallenge.completed) {
    currentChallenge = dates.find((d) => d.date === yesterday);
  }

  let currentStreak = 0;
  if (currentChallenge && currentChallenge.completed) {
    currentStreak = currentChallenge.streak;
  }

  let weeks = splitIntoWeeks(dates, 7);
  //  weeks = weeks.map((el) => el.reverse());
  weeks = weeks.reverse();

  return [weeks, currentStreak, noCompletedChallenges];
}

// calculates the number of months it needs to load in order to show all challenges completed by the user
function getDateList(challenges_list) {
  // it attempts to show the last 4 months of challenges
  let noMonths = 4;
  let monday = getPastMonday(new Date(), noMonths);

  if (challenges_list.length === 0) {
    return generateDateList(monday, getNextFriday(new Date()), challenges_list);
  }

  // if this does not include all completed challenges,
  // it adds two more months and tries again
  while (
    new Date(monday).getTime() > new Date(challenges_list[0].date).getTime()
  ) {
    noMonths += 2;
    monday = getPastMonday(new Date(), noMonths);
  }

  return generateDateList(monday, getNextFriday(new Date()), challenges_list);
}

function getDateListFull(challenges_list) {
  let noMonths = 4;
  let monday = getPastMonday(new Date(), noMonths);

  if (challenges_list.length === 0) {
    return generateDateListFull(
      monday,
      getNextFriday(new Date()),
      challenges_list
    );
  }

  while (
    new Date(monday).getTime() > new Date(challenges_list[0].date).getTime()
  ) {
    noMonths += 2;
    monday = getPastMonday(new Date(), noMonths);
  }

  return generateDateListFull(
    monday,
    getNextSunday(new Date()),
    challenges_list
  );
}

function getCurrentDayFormat() {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDate = `${year}-${month}-${day}`;
  return currentDate;
}

function getPrettyDateFormatCalendarNotification(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} | ${hours}:${minutes}`;
}

export {
  isNextWeekday,
  isNextDay,
  generateRandomWeekdays,
  getDateList,
  getDateListFull,
  getCurrentDayFormat,
  getPrettyDateFormatCalendarNotification,
};
