import { useEffect, useState } from "react";
import LearnContentElement from "../LearnContentElement";
import { getLearningArticles } from "../../utils/API/learn";
import { LogOut } from "../../utils/API/auth";
import { setNotification } from "../../utils/Notifications";
import { useNotifications } from "../../utils/NotificationContext";
import useCustomNavigate from "../../utils/CustomNavigate";

const LearnContent = () => {
  const [learningContent, setLearningContent] = useState(null);
  const navigator = useCustomNavigate();
  const { addNotification } = useNotifications();

  useEffect(() => {
    (async () => {
      try {
        let newLearningContent = await getLearningArticles();

        if (newLearningContent.code === 200) {
          setLearningContent(newLearningContent.articles);
        } else if (newLearningContent.code === 400) {
          addNotification(newLearningContent.message);
        } else if (newLearningContent.code === 403) {
          LogOut();
          setNotification("Please log in once again.");
          navigator("/");
          return;
        } else if (newLearningContent.code === 404) {
          return;
        } else if (newLearningContent.code === 500) {
          addNotification(newLearningContent.message);
        } else {
          addNotification(newLearningContent.message);
        }

        return;
      } catch (err) {
        addNotification("An error has occured while updating the user.");
      }
    })();
  }, []);

  if (!learningContent) return null;

  return learningContent.map((el, i) => {
    return <LearnContentElement key={i} element={el} />;
  });
};

export default LearnContent;
