import { useState } from "react";
import "./styles.css";
import { sendNotificationCongrats } from "../../utils/API/interaction";
import { useNotifications } from "../../utils/NotificationContext";
import { LogOut } from "../../utils/API/auth";

const ChallengeNotification = ({
  notification,
  onNotificationClose,
  notificationClosing,
}) => {
  const [congratsSent, setCongratsSent] = useState(false);

  const { addNotification } = useNotifications();

  const handleCongratsSent = async () => {
    setCongratsSent(true);

    let sendResult = await sendNotificationCongrats(
      notification.notification_id
    );

    if (sendResult.code === 200) {
    } else if (sendResult.code === 400) {
      addNotification(sendResult.message);
    } else if (sendResult.code === 403) {
      LogOut();
      addNotification("Please log in once again.");
      navigator("/");
      return;
    } else if (sendResult.code === 500) {
      addNotification(sendResult.message);
    } else {
      addNotification(sendResult.message);
    }

    setTimeout(() => {
      onNotificationClose();
    }, 1000);
  };

  const handleNotificationClose = () => {
    onNotificationClose();
  };

  return (
    <div
      className={`absolute challenge-notification z-top-4 ${
        notificationClosing ? "closing" : ""
      }`}
    >
      <div className="absolute close-button" onClick={handleNotificationClose}>
        <img src="assets/images/clear_small.png" alt="" />
      </div>
      <div className="info">
        <img src="assets/images/challenge-smiley.svg" alt="" />
        <div>{notification.message}</div>
      </div>
      <div className="interaction" onClick={handleCongratsSent}>
        {congratsSent ? "Congrats sent!" : "Send congrats!"}{" "}
        <img src="assets/images/confetti.png" alt="" />
      </div>
    </div>
  );
};

const ChallengeNotificationPage = ({ name }) => {
  return (
    <div className="challenge-notification-page">
      <img src="/assets/images/challenge-smiley.svg" alt="" />
      <div>{name}</div>
    </div>
  );
};

const CalendarNotification = ({ notification, onNotificationOpen }) => {
  return (
    <div className="absolute calendar-notification z-top-4 no-bg">
      <div className="absolute open-button no-bg " onClick={onNotificationOpen}>
        <img src="assets/images/arrow_down_small.png" alt="" />
      </div>
      <div className="info no-bg">
        <img src="assets/images/challenge-smiley.svg" alt="" />
        <div>{notification.message}</div>
      </div>
      <div className="absolute date no-bg  z-top-3">{notification.date}</div>
    </div>
  );
};

const CalendarNotificationOnPage = ({ notification }) => {
  return (
    <div className="calendar-notification-page no-bg">
      <div className="date-page z-top-4 no-bg">{notification.date}</div>
      <div className="info no-bg">
        <img src="/assets/images/challenge-smiley.svg" alt="" />
        <div className="no-bg">{notification.message}</div>
      </div>
    </div>
  );
};

const OfflineNotification = ({ show }) => {
  return (
    <div
      className="absolute offline-notification z-top-4"
      style={{ display: show ? "flex" : "none" }}
    >
      <img
        src="assets/images/no-internet.svg"
        alt="The user does not have internet."
      />
      <p>No Internet Connection</p>
    </div>
  );
};

export {
  ChallengeNotification,
  ChallengeNotificationPage,
  CalendarNotification,
  CalendarNotificationOnPage,
  OfflineNotification,
};
